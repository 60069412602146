<template>
    <div class="page-header align-items-start" style="font-size: 14px;" :style="styleObject">
        <span class="mask bg-white"></span>
  
        <div v-if="!loaderActivePayTopUp" class="container mw-100" style="padding-left: 0px;padding-right: 0px;" @click="hideDiv($event)">
            <div class="row">
                <div class="notifications">
                    <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
                    {{ error }}
                    </material-alert>
                </div>
                <div class="col-12">
                    <div class="card-body" style="background: var(--color);padding: 0px;">
                        <div class="flex-container">
                            <a @click="goBack" class="back-button">
                                <span class="material-icons md-30" style="line-height: inherit;color: black;margin-top: -2px;">close</span>
                            </a>
                            <div class="cart-title">
                                <!-- {{ MERCHANT_BRAND_NAME ? MERCHANT_BRAND_NAME + " - " : "\xa0" }} {{ branch?.branch_name + (tableInfo?.table_no ? (' - Table: ' + tableInfo?.table_no) : '') }}  -->
                                {{ branch?.branch_name + (tableInfo?.table_no ? (' - Table: ' + tableInfo?.table_no) : '') }} 
                                <div style="font-size:12px;color: black;margin-top: -4px;font-weight: normal;">Cart</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card-body" style="margin-top: 10px;margin-bottom: 10px;">
                        <div style="display: flex;align-items: center;justify-content: center;">
                            <div @click="handleTakeAwayOrDineIn()" class="takeaway-dinein-button" style="border: 1px solid var(--color);" :style="{ background: takeAwayOrDineIn == 2 ? 'var(--color)' : 'white', color: takeAwayOrDineIn == 1 ? 'black' : 'black' }">
                                Dine In
                            </div>
                            <div @click="handleTakeAwayOrDineIn()" class="takeaway-dinein-button" style="border: 1px solid var(--color);" :style="{ background: takeAwayOrDineIn == 1 ? 'var(--color)' : 'white', color: takeAwayOrDineIn == 1 ? 'black' : 'black' }">
                                Take Away
                            </div>
                        </div>
                    </div> -->
                    <div class="card-body" style="width: 100%;display: flex;padding-left: 0px;padding-right: 0px;padding-bottom: 3px;">
                        <div style="width:10%;height: 3px;align-self: center;"></div>
                        <div style="width:5.5%;align-items: center;display: flex;background: var(--color);border-radius: 30px;justify-content: center;font-size: 12px;color: white;">1</div>
                        <div style="width: 32.5%;height: 3px;background: var(--color);align-self: center;"></div>
                        <div style="width:5.5%;align-items: center;display: flex;background: var(--color);border-radius: 30px;justify-content: center;font-size: 12px;color: white;">2</div>
                        <div style="width: 32.5%;height: 3px;background: #bdbdbd;align-self: center;"></div>
                        <div style="width:5.5%;align-items:center;display:flex;background: white;border-radius:30px;justify-content:center;font-size:12px;color: black;border: 1px solid #bdbdbd;">3</div>
                        <div style="width: 10%;height: 3px;align-self: center;"></div>
                    </div>
                    <div class="card-body" style="width:100%;display:flex;padding-top:0px;padding-bottom:10px;justify-content: space-between;padding-left: 5%;padding-right: 5%;">
                        <!-- <div style="width: 5%;height: 3px;"></div> -->
                        <div style="align-items: center;display: flex;justify-content: center;color: #bdbdbd;text-wrap: nowrap">Add Item</div>
                        <!-- <div style="width: 19%;height: 3px;align-self: center;"></div> -->
                        <div style="align-items: center;display: flex;justify-content: center;color: black;text-wrap: nowrap">Review</div>
                        <!-- <div style="width: 19%;height: 3px;align-self: center;"></div> -->
                        <div style="align-items: center;display: flex;justify-content: center;color: #bdbdbd;text-wrap: nowrap">Checkout</div>
                        <!-- <div style="width: 5%;height: 3px;align-self: center;"></div> -->
                    </div>
                    <div class="card-body" style="padding-top: 0px;padding-top: 0px;padding-bottom: 15px;">
                        <div style="display: flex;">
                            <div class="branch-info" style="">
                                <div style="display: flex;align-items: center;" v-if="branch && branch.branch_address">
                                    <span class="material-icons color-green md-30" style="font-size: 20px;margin-right: 5px;width: 5%;">near_me</span>
                                    <div class="branch-info" style="line-height: normal;display: flex;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;-webkit-line-clamp: 2;">
                                        {{branch.branch_address}} <span class="material-icons" style="width: 15px;color: var(--color);font-size: 13.5px;">error_outline_rounded</span>
                                    </div>
                                    <!-- <span class="material-icons color-green ms-1" style="font-size: 16px">error_outline_rounded</span> -->
                                </div>
                                <div style="display: flex;align-items: center;margin-top: 10px;">
                                    <span class="material-icons color-green md-30" style="font-size: 20px;margin-right: 5px;">alarm</span>
                                    <div class="branch-info">
                                        Ready in about : {{branch.waiting_time_text}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="card-body" style="padding-top: 5px;padding-bottom: 5px;margin-top: 15px;">
                        <div style="display: flex;align-items: center;justify-content: space-between;">
                            <div class="text-order-item" style="font-size: 15px">
                                Order Items ({{ countQuantityItems() }})
                                <span v-if="showCartUpdatedAfterClearItemsOutStock" style="color: red">Cart Updated</span>
                            </div>
                            <div class="text-order-item" style="color: var(--color);font-weight: 600;" @click="goBack">
                                Add More
                            </div>
                        </div>
                        <div class="text-introduce" v-if="!isLogined">
                            <span @click="gotoSignIn()">Login</span> or <span @click="gotoSignIn()">Sign up</span> to enjoy greater benefits!
                        </div>
                        <!-- <div class="div-order-items" v-for="(item, index) in itemSelect" :key="index">
                            <div style="width: 20%">
                                <img :src="item.image" style="width: 50px;height: 50px;"/>
                            </div>
                            <div style="width: 80%">
                                <div class="div-items" style="position: relative;">
                                    <div class="item-name" style="width:63%">
                                        <span :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }"><span style="font-weight: bold;">{{ item.quantity + 'x' }}</span> {{ item.item_name }}</span>
                                        <div v-if="item.isCanGet == 1" style="color: var(--color);font-size: 12px;font-weight: bold;">NO MORE AVAILABLE</div>
                                    </div>
                                    <div class="item-price-format" style="width:30%;font-weight: bold;" :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }">
                                        {{ isLogined && item.member_price_no_modifier_option ? formatPrice(item.member_price_no_modifier_option * item.quantity) : formatPrice(item.price_default_no_modifier_option * item.quantity) }}
                                    </div>
                                    <div @click="removeItemFromItemsSelected(item.id, index)" style="width: 7%;height: fit-content;align-items: center;justify-content: center; cursor: pointer;">
                                        <span class="material-icons color-green md-20" style="font-size: 25px;">close</span>
                                    </div>
                                </div>
                                <div v-for="(modifierOption, indexModifierOption) in item.modifier_group" :key="indexModifierOption">
                                    <div style="position: relative;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                        <div class="item-name" style="width:63%">
                                            <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }"><span style="font-weight: bold;font-size: 18px;">{{ itemModifierOption.quantity + 'x' }}</span>  {{ itemModifierOption.item_name }}</span>
                                            <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;font-weight: bold;">NO MORE AVAILABLE</div>
                                        </div>
                                        <div class="item-price-format" style="width:30%;font-weight: bold;" v-if="!modifierOption.is_remark" :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }">
                                            {{ formatPrice(itemModifierOption.price * itemModifierOption.quantity) }}
                                        </div>
                                        <div @click="removeItemModifierFromItemsSelected(itemModifierOption.id)" style="width: 7%;height: fit-content;align-items: center;justify-content: center;">
                                            <span class="material-icons color-green md-20" style="font-size: 25px;">close</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div style="display: flex;width: 100%;margin-top: 10px;padding-left: 5px;padding-right: 5px;align-items: center;" v-for="(item, index) in itemSelect" :key="index">
                            <div style="width: 7%;" :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }">
                                <div style="border: 1px solid #D8DADA;text-align:center;">{{ item.quantity }}</div>
                            </div>
                            <div style="width: 3%"></div>
                            <div style="width: 90%;border-bottom: 0.5px solid #D8DADA;">
                                <div style="display: flex;align-items: center;">
                                    <div style="width: 15%;height: 70px">
                                        <img :src="item.image" style="width: 100%;height: 70px;"/>
                                    </div>
                                    <div style="width: 2%;height: 70px"></div>
                                    <div style="width: 60%;height: 70px">
                                        <div style="width: 100%;height:50%;display: flex;align-items: center;">
                                            <div style="width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }">
                                                {{ item.item_name }}
                                            </div>
                                        </div>
                                        <div style="width: 100%;height:50%;align-items: center;">
                                            <div style="width: 100%;font-size: 13px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: flex;" :id="'modifiersContainer_' + item.id">
                                                {{ getModifiersName(item) }}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div style="width: 3%;height: 70px"></div>
                                    <div style="width: 20%;text-align:right;" :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }">
                                        {{ getMainItemPriceAndModifiersPrice(item) }}
                                    </div>
                                </div>
                                <div v-if="item.isCanGet == 1" style="color: var(--color);font-size: 12px;font-weight: bold;">NO MORE AVAILABLE</div>
                            </div>
                        </div> -->
                        <div class="div-order-items" v-for="(item, index) in itemSelect" :key="index" style="display: flex;align-items: center;">
                            <div style="width: 100%;display: flex;align-items: center;height: 50px;" v-if="item.isEditQuantity" class="boxEditQuantity">
                                <div class="boxEditQuantity" @click="showQuantityForMainItem(index)" style="width: 17%;height: 100%;align-items: center;justify-content: center;display: flex;box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 5px;border-radius: 3px;">
                                    {{ item.quantity }}
                                </div>
                                <div style="width: 3%" class="boxEditQuantity"></div>
                                <div class="boxEditQuantity" style="width:48%;height:100%;align-items:center;justify-content:center;display:flex;box-shadow:rgba(0, 0, 0, 0.24) 0px 2px 5px;border-radius:3px;color: var(--color) !important;">
                                    <div class="boxEditQuantity" @click="handleQuantityForMainItem(item, 1, index)" style="width: 49%;align-items: center;display: flex;justify-content: center;font-size: 25px;">-</div>
                                    <div class="boxEditQuantity" style="width: 2%;border-left: 1px solid gray;height: 50%;"></div>
                                    <div class="boxEditQuantity" @click="handleQuantityForMainItem(item, 2, index)" style="width:49%;display:flex;align-items:center;justify-content:center;font-size: 25px;">+</div>
                                </div>
                                <div class="boxEditQuantity" style="width: 3%"></div>
                                <div class="boxEditQuantity" style="width: 30%;height: 100%;align-items: center;justify-content: flex-end;display: flex;color:#bdbdbd">
                                    {{ getMainItemPriceAndModifiersPrice(item) }}
                                </div>
                            </div>
                            <div style="width: 100%;display: flex;align-items: center;" v-else>
                                <div class="boxEditQuantity" style="width: 13.5%;height: 40px;" :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }" @click="showQuantityForMainItem(index)">
                                    <div class="boxEditQuantity" style="border: 1px solid #D8DADA;text-align:center;display: flex;align-items: center;justify-content: center;height: 100%;">
                                        {{ item.quantity }}
                                        <span class="material-icons color-green md-30 boxEditQuantity" style="font-size: 15px;margin-left: 5px;">keyboard_arrow_down</span>
                                    </div>
                                </div>
                                <div style="width: 3%"></div>
                                <div style="width: 83.5%;display: flex;border-bottom: 0.5px solid #D8DADA;padding-top: 10px;padding-bottom: 10px;">
                                    <div style="width: 20%;align-items: center;display: flex;">
                                        <img :src="item.image" style="width: 50px;height: 50px;"/>
                                    </div>
                                    <div style="width: 80%;align-items: center;justify-content: center;display: flex;">
                                        <div class="div-items" style="position: relative;">
                                            <div class="item-name" style="width: 70%;line-height: normal;word-break: break-word;">
                                                <span style="display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;-webkit-line-clamp: 2;" :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }">{{ item.description}}</span>
                                                <span style="display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;-webkit-line-clamp: 2;" :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }">{{ item.item_name}}</span>
                                                <div v-if="item.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>

                                                <div v-if="item.choice && item.choice_selected">
                                                    <div v-for="(itemChoice, indexChoice) in item.choice_selected.items" :key="indexChoice">
                                                        <div class="item-name" style="width:100%">
                                                            {{ itemChoice.qty + ' x ' + itemChoice.item_detail.item_name }}
                                                        </div>

                                                        <div v-for="(modifierOption, indexModifierOption) in itemChoice.item_detail.modifier_group" :key="indexModifierOption" class="ms-2">
                                                            <div style="position: relative;display: grid;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                                                <div class="div-items" v-for="(itemQuantity, indexQuantity) in itemModifierOption.quantity" :key="indexQuantity">
                                                                    <div v-if="itemModifierOption.description">
                                                                        <div class="item-name" style="width:100%">
                                                                            <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.description }}</span>
                                                                            <br /><span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                                            <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                                        </div>
                                                                    </div>
                                                                    <div v-else>
                                                                        <div class="item-name" style="width:100%">
                                                                            <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                                            <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="ms-2" v-if="itemModifierOption.item_in_mog">
                                                                    <div v-for="(subModifierOption, indexSubModifierOption) in itemModifierOption.item_in_mog.modifier_group" :key="indexSubModifierOption">
                                                                        <div style="position: relative;display: grid;" class="div-items" v-for="(subItemModifierOption, indexSubItemModifierOption) in subModifierOption.items" :key="indexSubItemModifierOption">
                                                                            <div class="item-name" style="width:100%">
                                                                                <span style="font-size: 12px;">{{ subItemModifierOption.item_name }}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 

                                                <div v-for="(modifierOption, indexModifierOption) in item.modifier_group" :key="indexModifierOption">
                                                    <div style="position: relative;display: grid;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                                        <div class="div-items" v-for="(itemQuantity, indexQuantity) in itemModifierOption.quantity" :key="indexQuantity">
                                                            <div v-if="itemModifierOption.description">
                                                                <div class="item-name" style="width:100%">
                                                                    <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.description }}</span>
                                                                    <br /><span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                                    <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <div class="item-name" style="width:100%">
                                                                    <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                                    <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="ms-2" v-if="itemModifierOption.item_in_mog">
                                                            <div v-for="(subModifierOption, indexSubModifierOption) in itemModifierOption.item_in_mog.modifier_group" :key="indexSubModifierOption">
                                                                <div style="position: relative;display: grid;" class="div-items" v-for="(subItemModifierOption, indexSubItemModifierOption) in subModifierOption.items" :key="indexSubItemModifierOption">
                                                                    <div class="item-name" style="width:100%">
                                                                        <span style="font-size: 12px;">{{ subItemModifierOption.item_name }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="item-price-format" style="width: 30%;opacity: 1;align-items: center;display: flex;justify-content: end;" :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }">
                                                <div style="display: grid;position: relative;width: 100%;">
                                                    <span v-if="item.member_price != item.price_default" style="color: #bdbdbd;text-decoration:line-through;font-size: 12px;position: absolute;top: -17px;right: 0px;">{{ getMainItem(item) }}</span>
                                                    {{ getMainItemPriceAndModifiersPrice(item) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="div-order-items" v-for="(item, index) in itemSelect" :key="index">
                            <div style="width: 20%">
                                <img :src="item.image" style="width: 50px;height: 50px;"/>
                            </div>
                            <div style="width: 80%">
                                <div class="div-items" style="position: relative;">
                                    <div class="item-name" style="width:63%">
                                        <span :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }"><span style="font-weight: bold;">{{ item.quantity + 'x' }}</span> {{ item.item_name }}</span>
                                        <div v-if="item.isCanGet == 1" style="color: var(--color);font-size: 12px;font-weight: bold;">NO MORE AVAILABLE</div>
                                    </div>
                                    <div class="item-price-format" style="width:30%;font-weight: bold;" :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }">
                                        {{ isLogined && item.member_price_no_modifier_option ? formatPrice(item.member_price_no_modifier_option * item.quantity) : formatPrice(item.price_default_no_modifier_option * item.quantity) }}
                                    </div>
                                    <div @click="removeItemFromItemsSelected(item.id, index)" style="width: 7%;height: fit-content;align-items: center;justify-content: center; cursor: pointer;">
                                        <span class="material-icons color-green md-20" style="font-size: 25px;">close</span>
                                    </div>
                                </div>
                                <div v-for="(modifierOption, indexModifierOption) in item.modifier_group" :key="indexModifierOption">
                                    <div style="position: relative;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                        <div class="item-name" style="width:63%">
                                            <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }"><span style="font-weight: bold;font-size: 18px;">{{ itemModifierOption.quantity + 'x' }}</span>  {{ itemModifierOption.item_name }}</span>
                                            <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;font-weight: bold;">NO MORE AVAILABLE</div>
                                        </div>
                                        <div class="item-price-format" style="width:30%;font-weight: bold;" v-if="!modifierOption.is_remark" :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }">
                                            {{ formatPrice(itemModifierOption.price * itemModifierOption.quantity) }}
                                        </div>
                                        <div @click="removeItemModifierFromItemsSelected(itemModifierOption.id)" style="width: 7%;height: fit-content;align-items: center;justify-content: center;">
                                            <span class="material-icons color-green md-20" style="font-size: 25px;">close</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="card-body" v-if="slides && slides.length > 0">
                        <div class="banner">
                            <swiper
                                :slides-per-view="1"
                                :space-between="0"
                                :loop="true"
                                :autoplay="{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }"
                                :pagination="{
                                    clickable: true,
                                    el: '.swiper-pagination',
                                }"
                                >
                                <swiper-slide v-for="(value, index) in slides" :key="index">
                                    <div class="slider" @click="clickLink(value)" style="position: relative;display: flex; align-items: center; justify-content: center;">
                                    <div v-if="value.direct_to_suggested_adds_on == 1 && value.itemSelected" style="position: absolute;width: 90%;height: 90%;align-items: center;display: flex;">
                                        <div style="width: 100%;height: 80%;display: inline-flex;gap:2%;">
                                        <div style="width: 49%;height: 100%;">
                                            <img
                                            :src=value.itemSelected.image
                                            alt="error"
                                            style="height: 100%;"
                                            />
                                        </div>
                                        <div style="width: 49%">
                                            <div style="background: gray;color:white;padding-left: 10px;">
                                            <div>ADDS ON ${{ value.itemSelected.price }}</div>
                                            </div>
                                            <div style="background: gray;color:white;margin-top:5px;padding-left: 10px;">
                                            <div>{{ value.itemSelected.item_name }}</div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <img
                                        :src=value.file_url
                                        alt="error"
                                    />
                                    </div>
                                </swiper-slide>
                            </swiper>
                            <div class="swiper-pagination"></div>
                        </div>
                    </div>
                    <div style="margin-top: 5px;" v-else>
                        
                    </div>
                    <div class="card-body" style="padding-top: 0px;">
                        <div class="total-section" style="width: 100%;text-align: end;">
                            <div class="total-label">
                                Subtotal
                            </div>
                            <div class="total-amount">
                                $ {{ calculateTotal().toFixed(2) }}
                            </div>
                        </div>
                        <!-- <hr class="divider" v-if="takeAwayOrDineIn == 2 && branch.service_charge_rate > 0" /> -->
                        <div v-if="isOrderPayLater" style="color: var(--color);" class="mt-1 text-end w-100">
                            <div v-if="accept_payment_on_moo">
                                <div>Subject to prevailing service charge and GST, if any</div>
                                <div @click="selectReward()" style="margin-top: 5px;color: #2DB550;font-size: 12px;display: flex;justify-content: left;align-items: center;">
                                    <span class="material-icons color-green md-30" style="font-size: 16px;margin-top: 0px;color:#2DB550 !important;margin-right: 3px;">discount</span>
                                    Use a voucher
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="fee-section" style="width: 100%;text-align: end;" v-if="(takeAwayOrDineIn == 2 || isOrderPayLater) && branch.service_charge_rate > 0">
                                <div class="fee-label">
                                    Service Charge
                                </div>
                                <div class="fee-amount">
                                    $ {{ totalDineInFee().toFixed(2) }}
                                </div>
                            </div>
                            <!-- <hr v-if="convertedValueGstRate() > 0" class="divider" /> -->
                            <div class="total-section" style="width: 100%;text-align: end;" v-if="convertedValueGstRate() > 0">
                                <div class="total-label">
                                    {{ Math.abs(branch.gst_rate) }}% GST
                                </div>
                                <div class="total-amount">
                                    $ {{ convertedValueGstRate().toFixed(2) }}
                                </div>
                            </div>
                            <div class="total-section" style="width: 100%;text-align: end;" v-if="convertedValueGstRate() == 0">
                                <div class="total-label">
                                    {{ Math.abs(branch.gst_rate) }}% GST
                                </div>
                                <div class="total-amount">
                                    $ {{ totalGSTInclusive().toFixed(2) }}
                                </div>
                            </div>
                            <!-- <hr class="divider" /> -->
                            <div class="total-section" style="width: 100%;text-align: end;">
                                <div @click="selectReward()" style="margin-top: 2px;color: #2DB550;font-size: 12px;position: absolute;text-align: center;align-items: center;display: flex;justify-content: center;">
                                    <span class="material-icons color-green md-30" style="font-size: 16px;margin-top: 0px;color:#2DB550 !important;margin-right: 3px;">discount</span>
                                    Use a voucher
                                </div>
                                <div class="total-label" v-if="convertedValueGstRate() > 0">
                                    Total
                                </div>
                                <div class="total-label" v-else>
                                    Total Incl. GST
                                </div>
                                <div class="total-amount">
                                    $ {{ calculateTotalBeforeDiscount().toFixed(2) }}
                                </div>
                            </div>
                        </div>
                        <!-- <hr class="divider" v-if="convertedValueGstRate() == 0" /> -->

                        <div style="position: relative;margin-top: 10px;width: 100%;border: 1px solid #bdbdbd;border-radius: 10px;" v-if="rewardSelect && rewardSelect.length > 0">
                            <div style="width: 100%;padding: 10px;">
                                <div style="display: flex;justify-content: space-between;align-items: center;gap: 10px;">
                                    <div style="display: flex;align-items: center;gap: 5px;">
                                        <span class="material-icons color-green md-30" style="font-size: 25px;color:#E10072 !important;">discount</span>
                                        <div style="">{{ rewardSelect[0].reward_title }}</div>
                                    </div>
                                    <div style="color: #E10072;font-weight: 600;">
                                        {{ voucher_deducted_format ? "-" + voucher_deducted_format : "-$ 0.00" }}
                                    </div>
                                </div>
                                <div style="width: 100%;border-bottom: 1px dashed #bdbdbd;margin-top: 8px;margin-bottom: 8px;"></div>
                                <div style="display: flex;justify-content: space-between;align-items: center;gap: 10px;">
                                    <!-- <div v-if="checkEnableVoucher()" class="order-value-is-not"> -->
                                    <div v-if="messageErrorVoucher" class="order-value-is-not">
                                        <!-- Order value is not eligible to apply this voucher. -->
                                        {{ messageErrorVoucher }}
                                    </div>
                                    <div v-else style="color: rgb(61, 131, 99);display: flex;align-items: center;gap: 5px;">
                                        <span class="material-icons color-green md-30" style="font-size: 15px;color:#3D8363 !important;margin-top: 1px;">check_circle_outline</span>
                                        <div style="color: #3D8363;">
                                            Voucher applied!
                                        </div>
                                    </div>
                                    <div style="color: #E10072;font-weight: 600;" @click="removeReward()">
                                        Remove
                                    </div>
                                </div>
                            </div>
                            <div style="width: 12px;height: 12px;background-color: #FCFCFD;position: absolute;top: 46%;left: -8px;border-radius: 10px;border-right: 1px solid #bdbdbd;"></div>
                            <div style="width: 12px;height: 12px;background-color: #FCFCFD;position: absolute;top: 46%;right: -8px;border-radius: 10px;border-left: 1px solid #bdbdbd;"></div>
                        </div>
                    </div>
                    <!-- <div v-if="!isOrderPayLater && branch && branch.show_special_instructions_on_ordering_online == 1" class="line"></div> -->
                    <div v-if="branch && branch.show_special_instructions_on_ordering_online == 1" class="line"></div>
                    <!-- <div v-if="!isOrderPayLater && branch && branch.show_special_instructions_on_ordering_online == 1" class="card-body" style="padding-top: 5px;padding-bottom: 5px;"> -->
                    <div v-if="branch && branch.show_special_instructions_on_ordering_online == 1" class="card-body" style="padding-top: 5px;padding-bottom: 5px;">
                        <!-- <div class="div_root_cutlery">
                            <div class="inline-flex-container" style="align-items: center; gap: 5px;">
                                <div class="icon-container">
                                    <img src="../assets/img/my-cart/cutlery.png" alt="cutlery" class="cutlery-icon"/>
                                </div>
                                <div class="text-container">Cutlery</div>
                            </div>

                            <div>
                                <div
                                    class="
                                        col-lg-8 col-md-8 col-12
                                        d-flex
                                        align-items-center
                                    "
                                    >
                                    <div
                                        :class="{ active: isActiveCutlery }"
                                        class="toggle_container mt-2"
                                    >
                                        <MaterialToggleButton
                                            labelEnableText=""
                                            labelDisableText=""
                                            id="event_handle_cutlery"
                                            v-on:change="triggerEventCutlery"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <hr style="margin: 0px;" /> -->
                        <div>
                            <div class="div_root_cutlery">
                                <div class="div_notes">
                                    <div class="image_notes">
                                        <img src="../assets/img/my-cart/notes.jpeg" style="width: 25px;height: 25px;"/>
                                    </div>
                                    <div class="color-black">Special Instructions</div>
                                </div>
                                <!-- <div>
                                    <div @click="openModalNotes()" class="div_arrow">
                                        <div class="mt-3 mb-3 custom-note">{{ notes }}</div>
                                        <span class="material-icons icon-next md-30">navigate_next_icon</span>
                                    </div>
                                </div> -->
                                <div>
                                    <material-input
                                        v-model="notes"
                                        placeholder="Enter here"
                                        maxLength="15"
                                    />
                                </div>
                            </div>
                            
                        </div>
                        <!-- <hr style="margin: 0px;" /> -->
                        <!-- <div v-if="rewardSelect && rewardSelect.length > 0">
                            <div v-if="checkEnableVoucher()" class="order-value-is-not">
                                Order value is not eligible to apply this voucher.
                            </div>
                        </div>
                        <div class="div_root_cutlery" @click="selectReward()">
                            <div class="div_notes">
                                <div class="image_notes">
                                    <img src="../assets/img/my-cart/voucher.png" style="width: 25px;height: 25px;"/>
                                </div>
                                <div class="color-black">Voucher</div>
                            </div>
                            <div>
                                <div v-if="checkEnableVoucher()" class="div_arrow">
                                    <div v-if="rewardSelect && rewardSelect.length > 0" class="mt-2 mb-2 reward_title">
                                        {{ rewardSelect[0].reward_title }}
                                    </div>
                                    <div v-else class="mt-2 mb-2 select-voucher">
                                        Select Voucher
                                    </div>
                                    <span class="material-icons color-green md-30 icon-select-voucher">navigate_next_icon</span>
                                </div>
                                <div v-else class="div_arrow">
                                    <div v-if="rewardSelect && rewardSelect.length > 0" class="mt-2 mb-2 reward_title_active">
                                        {{ rewardSelect[0].reward_title }}
                                    </div>
                                    <div v-else class="mt-2 mb-2" clas="select-voucher">
                                        Select Voucher
                                    </div>
                                    <span class="material-icons color-green md-30 icon-select-voucher">navigate_next_icon</span>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div v-if="isLogined" style="margin-top: 4px;"> -->
                            <!-- <hr style="margin: 0px;" /> -->
                            <!-- <div class="div_root_cutlery">
                                <div class="div_notes">
                                    <div class="image_notes">
                                        <img src="../assets/img/my-cart/dolar.png" style="width: 25px;height: 25px;"/>
                                    </div>
                                    <div class="color-black">Use $ 0.00 cashback</div>
                                </div>
                                <div class="div_notes">
                                    <div style="color:var(--color);">-$ 0.00</div>
                                    <div
                                        class="
                                            d-flex
                                            align-items-center
                                        "
                                        >
                                        <div
                                            :class="{ active: isActiveUseCashback }"
                                            class="toggle_container mt-3"
                                        >
                                            <MaterialToggleButton
                                                labelEnableText=""
                                                labelDisableText=""
                                                id="event_handle_use_cashback"
                                                v-on:change="triggerEventUseCashback"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        <!-- </div> -->
                    </div>
                    <!-- <div class="line"></div>
                    <div class="card-body">
                        <div class="flex-container">
                            <div class="inline-flex-container">
                                <div class="icon-container">
                                    <span class="material-icons color-green md-30" id="eventIcon">event_note</span>
                                </div>
                                <div class="title-text" style="font-size:20px;">Bill</div>
                            </div>
                        </div>
                        <div class="flex-container">
                            <div class="inline-flex-container">
                                <div class="text-container">Item total</div>
                            </div>
                            <div class="text-container">$ {{ calculateTotalAfterGST().toFixed(2) }}</div>
                        </div>
                        <div class="flex-container">
                            <div class="inline-flex-container">
                                <div class="text-container">Voucher Discount</div>
                            </div>
                            <div class="var(--color)-text">{{ voucher_deducted_format ? "-" + voucher_deducted_format : "-$ 0.00" }}</div>
                        </div> -->
                        <!-- <div class="flex-container" v-if="isLogined">
                            <div class="inline-flex-container">
                                <div class="text-container">Cashback Deduct</div>
                            </div>
                            <div class="var(--color)-text">-$ 0.00</div>
                        </div> -->
                        <!-- <div class="flex-container">
                            <div class="inline-flex-container">
                                <div class="text-container">Payment Amount</div>
                            </div> -->
                            <!-- <div class="var(--color)-text bold-text">${{ (calculateTotal() - totalVoucherDiscount).toFixed(2) }}</div> -->
                            <!-- <div class="var(--color)-text">$ {{ finalTotal() }}</div>
                        </div> -->
                        <!-- <div class="flex-container" v-if="isLogined">
                            <div class="inline-flex-container">
                                <div class="icon-container">
                                    <img src="../assets/img/my-cart/dolar.png" alt="dolar" class="cashback-icon"/>
                                </div>
                                <div class="text-container">Cashback Earned</div>
                            </div>
                            <div class="text-container">[+$ 0.00]</div>
                        </div> -->
                    <!-- </div> -->

                    
                    <!-- <div class="card-body" style="padding-bottom: 0px;" v-if="false">
                        <div class="flex-container" style="margin-bottom: 10px;">
                            <div class="inline-flex-container">
                                <div class="title-text" style="font-size: 15px">Number of Pax:</div>
                            </div>
                        </div>
                        <div class="d-flex mb-1" v-for="(item, index) in tableInfo.pax" :key="index">
                            <input id="guestInput" v-model="guest" :value="index + 1" class="radio-input" type="radio"/>
                            <div class="text-container ms-3">Guest: {{ index + 1 }}</div>
                        </div>
                    </div> -->
                    <div class="card-body" style="padding-bottom: 0px;" v-if="!isOrderPayLater && !queue">
                        <div class="flex-container" style="margin-bottom: 10px;">
                            <div class="inline-flex-container">
                                <div class="title-text" style="font-size: 15px">Payment Methods:</div>
                            </div>
                        </div>
                        <div v-if="allow_payment_by_minty_wallet == 1 || allow_payment_by_minty_wallet == '1'">
                            <div v-if="isLogined" class="flex-container" @click="selectInputMintyWallet">
                                <div class="inline-flex-container">
                                    <!-- <div class="icon-container">
                                        <img src="../assets/img/my-cart/minty_wallet.png" alt="minty_wallet" class="wallet-icon"/>
                                    </div> -->
                                    <span class="material-icons color-green md-30" style="width: 30px;">monetization_on</span>
                                    <div class="text-container">
                                        E-Wallet (Balance: {{ memberInfo && memberInfo.amount_in_account_format ? memberInfo.amount_in_account_format : "$ 0.00" }}) <span v-if="disablePayWithMintyWallet" style="font-size:11px;color:red">insufficient</span>
                                        <div v-if="memberInfo" style="color: #2EB34F;">Logged in as {{  memberInfo.name ? memberInfo.name : "" }} {{  memberInfo.last_name ? memberInfo.last_name : "" }}</div>
                                    </div>
                                    <div v-if="isCheckMerchantConnectedStripe === true && memberPermissions.includes('Top-up') && isLogined && memberInfo && Number(Number(memberInfo.amount_in_account).toFixed(2)) < Number(finalTotal())">
                                    <!-- <div> -->
                                        <span style="font-size: 20px;" v-if="openBoxMintyWallet" class="material-icons color-green md-30" @click="handleOpenBoxMintyWallet">keyboard_arrow_up</span>
                                        <span style="font-size: 20px;" v-else class="material-icons color-green md-30" @click="handleOpenBoxMintyWallet">keyboard_arrow_down</span>
                                    </div>
                                </div>
                                <div>
                                    <div class="inline-flex-container gap-container">
                                        <div class="mt-3 mb-3 inline-flex-container gap-container">
                                            <!-- <div class="var(--color)-bold-text" style="margin-right: 5px;">$ {{ finalTotal() }}</div> -->
                                            <input @click="selectInputMintyWallet" :disabled="disablePayWithMintyWallet" id="paymentMethodMintyWalletInput" v-model="selectPaymentMethod" value="1" class="radio-input" type="radio"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-container" v-if="promotionalTopupList.length">
                        <!-- <div class="flex-container" v-if="isCheckMerchantConnectedStripe === true && memberPermissions.includes('Top-up')"> -->
                            <div style="width: 10%;"></div>
                            <div style="width: 90%;">
                                <!-- <div class="margin-top-text">Balance {{ memberInfo && memberInfo.amount_in_account_format ? memberInfo.amount_in_account_format : "$ 0.00" }}</div> -->
                                <!-- <div v-if="memberInfo && Number(Number(memberInfo.amount_in_account).toFixed(2)) < Number(finalTotal())" class="inline-flex-container gap-container margin-top-text" style="height: auto;width: 60%; text-align: left;"> -->
                                <div v-for="(promotionalTopup, index) in promotionalTopupList" :key="index" class="inline-flex-container gap-container margin-top-text pe-2">
                                    <div @click="openModalConfirmPromotionalTopup(promotionalTopup)" class="voucher-container">
                                        <div class="inline-flex-container align-items-center">
                                            <img src="../assets/img/my-cart/minty_wallet.png" alt="minty_wallet" class="wallet-icon"/>
                                            <div class="white-bold-text" style="font-size: 16px;">{{ 'x' + promotionalTopup.payment_amount + ' top-up and your payment’s totally free' }}</div>
                                        </div>
                                        <div class="padding-container">
                                            <div class="voucher-text">Payment Amount: S${{ parseFloat(finalTotal() * promotionalTopup.payment_amount).toFixed(2)  }}</div>
                                            <!-- <div class="voucher-text">Expiry: {{ promotionalTopup.expiry_text }}</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-container" v-if="isCheckMerchantConnectedStripe === true && memberPermissions.includes('Top-up') && isLogined && openBoxMintyWallet && memberInfo && Number(Number(memberInfo.amount_in_account).toFixed(2)) < Number(finalTotal())">
                        <!-- <div class="flex-container" v-if="isCheckMerchantConnectedStripe === true && memberPermissions.includes('Top-up')"> -->
                            <div style="width: 10%;"></div>
                            <div style="width: 90%;">
                                <!-- <div class="margin-top-text">Balance {{ memberInfo && memberInfo.amount_in_account_format ? memberInfo.amount_in_account_format : "$ 0.00" }}</div> -->
                                <div class="not-enough-text">(Not enough to pay)</div>
                                <!-- <div v-if="memberInfo && Number(Number(memberInfo.amount_in_account).toFixed(2)) < Number(finalTotal())" class="inline-flex-container gap-container margin-top-text" style="height: auto;width: 60%; text-align: left;"> -->
                                <div v-for="(item, index) in topupList" :key="index" class="inline-flex-container gap-container margin-top-text" style="height: auto;width: 60%; text-align: left;">
                                    <div @click="openModalConfirmTopup(item)" class="voucher-container">
                                        <div class="inline-flex-container align-items-center">
                                            <img src="../assets/img/my-cart/minty_wallet.png" alt="minty_wallet" class="wallet-icon"/>
                                            <div class="white-bold-text">{{ item.topup_name }}</div>
                                        </div>
                                        <div class="padding-container">
                                            <div class="voucher-text">Get S${{ Number(item.payment_amount).toFixed(2) }} {{ item.bonus_amount && item.bonus_amount > 0 ? " + S$"+Number(item.bonus_amount).toFixed(2) : "" }}</div>
                                            <!-- <div class="voucher-text">Get $40 valued voucher set</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-container" @click="selectInputStripe">
                            <div class="inline-flex-container">
                                <div class="icon-container">
                                    <span class="material-icons color-green md-30" style="width: 30px;">credit_card</span>
                                </div>
                                <div class="text-container">Debit/Creditcard</div>
                            </div>
                            <div>
                                <div class="mt-3 mb-3">
                                    <input @click="selectInputStripe" id="paymentMethodStripeInput" v-model="selectPaymentMethod" value="2" class="radio-input" type="radio"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <hr /> -->
                    <div class="card-body" style="margin-top: 10px;" v-if="!queue">
                        <div v-if="isCanPay == 0" style="color: red;font-weight:bold;margin-bottom: 5px;">Please remove NO MORE AVAILABLE items from Cart to proceed payment!</div>
                        <!-- <div class="flex-container div-item-total">
                            <div class="grid-container">
                                <div class="flex-container text-item-total">
                                    <div class="bold-text">Total</div>
                                    <div class="bold-text" style="color: var(--color);">$ {{ finalTotal() }}</div>
                                </div>
                                <div class="flex-container text-item-total">
                                    <div class="light-text">You save</div>
                                    <div class="var(--color)-light-text">{{ voucher_deducted_format || Number(calculateTotalYouSave()) ? "$ "+(Number(totalVoucherDiscount) + Number(calculateTotalYouSave())).toFixed(2) : "$ 0.00" }}</div>
                                </div>
                            </div>
                        </div> -->
                        <div style="margin-bottom: 30px;" v-if="display_special_request_in_pos == '1' || display_special_request_in_pos == '2' || display_special_request_in_pos == 1 || display_special_request_in_pos == 2">
                            <div>Special requests and preferences</div>
                            <div>How would you like your food:</div>
                            <div style="margin-top: 10px;display: flex;gap: 10px;" @click="selectNonSpicy">
                                <input id="non-spicy" v-model="selectSpicyValue" value="不辣 Non-Spicy" class="radio-input" type="radio"/>
                                <div>不辣 Non-Spicy</div>
                            </div>
                            <div style="margin-top: 5px;display: flex;gap: 10px;" @click="selectSpicy">
                                <input id="spicy" v-model="selectSpicyValue" value="辣 Spicy" class="radio-input" type="radio"/>
                                <div>辣 Spicy</div>
                            </div>
                        </div>
                        <div v-if="memberInfo">
                            <!-- <div v-if="disablePayWithMintyWallet && selectPaymentMethod == 1 || checkEnableVoucher() || Number(finalTotal()) < 0 || !itemSelect || (itemSelect && itemSelect.length == 0) || isCanPay == 0" class="disabled-pay-button"> -->
                            <div v-if="disablePayWithMintyWallet && selectPaymentMethod == 1 || messageErrorVoucher != '' || Number(finalTotal()) < 0 || !itemSelect || (itemSelect && itemSelect.length == 0) || isCanPay == 0" class="disabled-pay-button">
                                {{ isOrderPayLater ? 'Submit order' : ('Pay $ ' + finalTotal()) }}
                            </div>
                            <div v-else>
                                <div v-if="payLoad" class="pay-button">
                                    Loading...
                                </div>
                                <div v-else class="pay-button" @click="reloadOrder(1)">
                                    {{ isOrderPayLater ? 'Submit order' : ('Pay $ ' + finalTotal()) }}
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div>
                                <div v-if="Number(finalTotal()) < 0 || !itemSelect || (itemSelect && itemSelect.length == 0) || isCanPay == 0" class="disabled-pay-button">
                                    {{ isOrderPayLater ? 'Submit order' : ('Pay $ ' + finalTotal()) }}
                                </div>
                                <div v-else>
                                    <div v-if="payLoad" class="pay-button">
                                        Loading...
                                    </div>
                                    <div v-else class="pay-button" @click="reloadOrder(1)">
                                        {{ isOrderPayLater ? 'Submit order' : ('Pay $ ' + finalTotal()) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 10px;text-align: center;">Powered by Minty™</div>
                    </div>
                    <div v-else style="margin-top: 10px;text-align: center;">Powered by Minty™</div>
                </div>
            </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
            <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
            />
        </div>
        <material-loading :active="loaderActive || loaderActivePayTopUp" />
        <div>
            <button
                style="display: none"
                type="button"
                class="btn-notes-modal"
                data-bs-toggle="modal"
                data-bs-target="#modalShowNotes"
            ></button>
            <div
                class="modal fade"
                id="modalShowNotes"
                tabindex="-1"
                aria-labelledby="modalShowNotes"
                aria-hidden="true"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <material-textarea
                                rows="12"
                                v-model="notes"
                                id="notes"
                                name="notes"
                                placeholder="Specify your requirements that you want us to follow"
                            />
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-success btn-close-modal btn-sm"
                                data-bs-dismiss="modal"
                                style="color: white !important;background: var(--color) !important;border-radius: 10px !important;"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button
                style="display: none"
                type="button"
                class="btn-confirm-topup-modal"
                data-bs-toggle="modal"
                data-bs-target="#modalConfirmTopup"
            ></button>
            <div
                class="modal fade"
                id="modalConfirmTopup"
                tabindex="-1"
                aria-labelledby="modalConfirmTopup"
                aria-hidden="true"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header" style="">
                            TOP UP MINTY WALLET
                        </div>
                        <div class="modal-body">
                            <div v-if="topupCurrentlyHandle">Top Up Amount: S${{ Number(topupCurrentlyHandle.topup_amount).toFixed(2) }}</div>
                            <div v-if="topupCurrentlyHandle && topupCurrentlyHandle.bonus_amount && topupCurrentlyHandle.bonus_amount > 0">Bonus Amount: S${{ Number(topupCurrentlyHandle.bonus_amount).toFixed(2) }}</div>
                            <div v-if="topupCurrentlyHandle">Payment Amount: S${{ Number(topupCurrentlyHandle.payment_amount).toFixed(2) }}</div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-close-confirm-topup-modal btn-sm"
                                data-bs-dismiss="modal"
                                style="color: white !important;background: gray !important;border-radius: 10px !important;"
                            >
                                Cancel
                            </button>
                            <button
                                @click="topupCurrentlyHandle && topupCurrentlyHandle.promotion_type ? reloadOrder(1, 1) : processPaymentTopUp()"
                                type="button"
                                class="btn btn-sm"
                                style="color: white !important;background: var(--color) !important;border-radius: 10px !important;"
                            >
                                Pay
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button
                style="display: none"
                type="button"
                class="btn-timeout-order-modal"
                data-bs-toggle="modal"
                data-bs-target="#modalTimeOutOrder"
            ></button>
            <div
                class="modal fade"
                id="modalTimeOutOrder"
                tabindex="-1"
                aria-labelledby="modalTimeOutOrder"
                aria-hidden="true"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div>Order submission failed due to no response from the system. Please send another order or contact staff for help.</div>
                            <!-- <div>Please contact staff</div> -->
                        </div>
                        <div class="modal-footer">
                            <button
                                @click="closedModalOrderFail()"
                                type="button"
                                class="btn btn-sm"
                                style="color: white !important;background: var(--color) !important;border-radius: 10px !important;"
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalNotification :message="txtNotification"/>
    <ModalNotificationSignIn :message="'Please login to use voucher!'"/>
    <ModalNotificationOutStockItems :itemSelect="itemSelect"/>

  </template>
  
<script>
    import MaterialAlert from "@/components/MaterialAlert.vue";
    import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
    import MaterialLoading from "@/components/MaterialLoading.vue";
    // import MaterialToggleButton from "@/components/MaterialToggleButton.vue";
    import $ from "jquery";
    import MaterialTextarea from "@/components/MaterialTextarea.vue";
    import PromocodeService from "../services/promocode.service";
    import OrderService from "../services/order.service";
    import AuthService from "../services/auth.service";
    // import axios from "axios";
    // import PromocodeService from "../services/promocode.service";
    // import moment from 'moment';
    // import $ from "jquery";
    // import TokenService from "../services/token.service";
    import SlideService from "../services/slide.service";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import SwiperCore, { Pagination, Autoplay } from "swiper";
    import "swiper/swiper-bundle.min.css";
    import "swiper/swiper.min.css";
    import ModalNotification from '@/components/ModalNotification.vue';
    import ModalNotificationSignIn from '@/components/ModalNotificationSignIn.vue';
    import MaterialInput from "@/components/MaterialInput.vue";
    import ModalNotificationOutStockItems from '@/components/ModalNotificationOutStockItems.vue';
    import EventBus from "vue3-eventbus";
    import MemberService from "../services/member.service";
    import { gotoMemberLogin, localStorageName } from "@/config/utils";

    SwiperCore.use([Pagination, Autoplay]);
  
    export default {
        name: "My Cart",
        components: {
            MaterialAlert,
            MaterialSnackbar,
            MaterialLoading,
            // MaterialToggleButton,
            MaterialTextarea,
            Swiper,
            SwiperSlide,
            ModalNotification,
            ModalNotificationSignIn,
            MaterialInput,
            ModalNotificationOutStockItems
        },
        data() {
            return {
                snackbar: null,
                error: '',
                paymentMethod: '',
                branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
                cutleryChecked: true,
                memberInfo: JSON.parse(localStorage.getItem(localStorageName('memberInfo'), '')),
                memberId: 0,
                memberPhone: null,
                memberName: null,
                showErrorName: false,
                showErrorPhone: false,
                loaderActive: false,
                myRewardData: [],
                searchKeyword: '',
                selectedItem: null,
                selectVoucher: false,
                progressPercentage: 10, // Set the initial percentage here
                progressBarColor: `linear-gradient(90deg, red 50%, gray 50%)`,
                contentTermAndCondition: '',
                countSelectedCashbackReward: 0,
                countTotalSelectedReward: 0,
                selectedReward: [],
                showMessageLimitSelectedReward: '',
                isActiveCutlery: false,
                isActiveUseCashback: false,
                takeAwayOrDineIn: 2, // 1 = take away | 2 = dine in
                itemSelect: [],
                rewardSelect: JSON.parse(localStorage.getItem(localStorageName('SELECTED_REWARD'), [])),
                take_away_fee: 0,
                notes: localStorage.getItem(localStorageName('ORDER_NOTES'), ''),
                selectPaymentMethod: localStorage.getItem(localStorageName('PAYMENT_METHOD'), 1),
                disablePayWithMintyWallet: false,
                openBoxMintyWallet: true,
                totalVoucherDiscount: 0,
                voucher_deducted_format: "",
                voucher_deducted: "",
                isLogined: false,
                isYourFriendLogined: false,
                slides: [],
                isCanPay: 1,
                cartName: 'ORDER_INFO',
                txtNotification: '',
                MERCHANT_BRAND_NAME: "",
                isSaveOrder: false,
                messageErrorVoucher: "",
                payLoad: false,
                merchantCode: "",
                branchCode: "",
                pathType: "",
                tableCode: "",
                tableInfo: null,
                isOrderPayLater: false,
                guest: localStorage.getItem(localStorageName('NUMBER_OF_GUESTS'), 0),
                showCartUpdatedAfterClearItemsOutStock: false,
                topupList: [],
                promotionalTopupList: [],
                topupCurrentlyHandle: null,
                memberPermissions: [],
                isCheckMerchantConnectedStripe: false,
                loaderActivePayTopUp: false,
                date_time_send_to_megapos: null,
                intervalId: null,
                timeoutId: null,
                // arrayMintyTransactionDetail: [],
                // arrayMintyQRTransactionDetail: [],
                // arrayMintyOnlineOrderModifierByItem: [],
                // arrayMintyQROnlineOrderModifierByItem: [],
                stopOrdering: 0,
                selectSpicyValue: "不辣 Non-Spicy",
                display_special_request_in_pos: localStorage.getItem(localStorageName('DISPLAY_SPECIAL_REQUEST_IN_POS'), ""),
                allow_payment_by_minty_wallet: localStorage.getItem(localStorageName('ALLOW_PAYMENT_BY_MINTY_WALLET'), ""),
                accept_payment_on_moo: true,
                isPaying: false,
                queue: false
            };
        },
        mounted() {
            EventBus.on("ClearItemsOutStockSuccess", async () => {
                this.isCanPay = 1;
                this.showCartUpdatedAfterClearItemsOutStock = true;

                if(this.memberInfo && this.memberInfo.amount_in_account) {
                    var amount_in_account = Number(this.memberInfo.amount_in_account);

                    if(Number(amount_in_account.toFixed(2)) < Number(this.finalTotal())) {
                        this.selectPaymentMethod = 2;
                        this.disablePayWithMintyWallet = true;
                    } else {
                        this.selectPaymentMethod = 1;
                        this.disablePayWithMintyWallet = false;
                    }

                    localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
                    localStorage.setItem(localStorageName('PAYMENT_METHOD'), this.selectPaymentMethod);
                }
                // this.reloadOrder();
            });
        },
        beforeUnmount() {
            window.removeEventListener('popstate', this.clearIsEditQuantity());
        },
        async created() {
            this.cartName = await this.$store.dispatch('cartName');
            this.merchantCode = this.$store.state.merchantCode;
            this.branchCode = this.$store.state.branchCode;
            this.pathType = this.$route.params.type;
            this.tableCode = this.$route.params.table_code;
            this.tableInfo = JSON.parse(localStorage.getItem('table_' + this.merchantCode.toLowerCase() + '_' + this.branchCode.toLowerCase() + '_' + this.tableCode.toLowerCase(), null));
            this.queue = this.$route.query.queue ?? false;

            if (this.queue) {
                this.cartName = this.cartName.replace('ORDER_INFO_', 'ORDER_QUEUE_INFO_')
            }

            //load after payment done
            var session_id = this.$route.query.session_id;
            var price = this.$route.query.price;
            var id = this.$route.query.id;
            var transaction_id = this.$route.query.transaction_id;

            if(session_id) { // call back after pay success - stripe
                this.loaderActivePayTopUp = true;
                MemberService.addDirectAmountAfterPayWithCard(session_id, price, id, this.merchantCode).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            // this.snackbar = "success";
                            // this.message = "Topup Successfully!";
                            // this.setTimeOutNotification();
                            var url = location.protocol + '//' + location.host + "/" + this.merchantCode;
                            if (this.tableInfo) {
                                if (transaction_id) {
                                    url += "/QR/"+this.branchCode+"/"+this.tableCode+"/payment-success/"+transaction_id;
                                } else {
                                    url += "/QR/"+this.branchCode+"/"+this.tableCode+"/my-cart";
                                }
                            } else {
                                if (transaction_id) {
                                    url += "/"+this.branchCode+"/payment-success/"+transaction_id;
                                } else {
                                    url += "/"+this.branchCode+"/my-cart";
                                }
                            }

                            window.location.href = url;
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                            this.loaderActivePayTopUp = false;
                        }
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.setTimeOutNotification();
                        this.loaderActivePayTopUp = false;
                    }
                );
            } else {
                if (this.tableInfo) {
                    this.accept_payment_on_moo = this.tableInfo.accept_payment_on_moo ?? false;

                    if (this.tableInfo.stripe_connect) {
                        this.isOrderPayLater = false;
                    } else {
                        this.isYourFriendLogined = this.queue ? false : this.tableInfo.is_member_order ?? false;
                        this.isOrderPayLater = true;
                    }
                } else {
                    this.isOrderPayLater = false;
                }
                this.itemSelect = JSON.parse(localStorage.getItem(this.cartName, []))
                localStorage.removeItem(localStorageName('ORDER_NOTES'));
                localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
                // var memberInfoData = JSON.parse(localStorage.getItem("memberInfo", ''));
                // this.memberName = memberInfoData.name;
                // this.memberPhone = "+"+memberInfoData.phone_code+memberInfoData.phone_number;
                // this.memberId = memberInfoData.id;

                if (!this.isSaveOrder) {
                    if (!this.itemSelect || this.itemSelect.length == 0) {
                        this.gotoPath('Order');
                    }
                }

                if(this.memberInfo || this.isYourFriendLogined) {
                    if (this.memberInfo) {
                        this.memberId = this.memberInfo.id ?? 0;   
                    }
                    this.isLogined = true;
                }

                this.getMemberInfo();
                this.getSlides();
                // this.checkMerchantAndBranch();
                this.reloadOrder();
                this.checkMemberPermissions();

                if (!this.isOrderPayLater) {
                    this.getTopupList();
                }
            }
        },
        computed: {
            styleObject() {
                return {
                    "--color": this.$store.state.color,
                };
            },
        },
        methods: {
            formatPrice(price) {
                return 'S$ ' + parseFloat(price).toFixed(2);
            },
            closeSnackbar() {
                this.snackbar = null;
            },
            setTimeOutNotification() {
                setTimeout(() => {
                    this.snackbar = null;
                }, 3000);
            },
            showLoader() {
                this.loaderActive = true;
            },
            hideLoader() {
                this.loaderActive = false;
            },
            goBack() {
                this.gotoPath(this.queue ? 'Queue' : 'Order');
            },
            gotoSignIn() {
                // this.showLoader();
                // const merchantCode = this.merchantCode ? `/${this.merchantCode}` : "";

                gotoMemberLogin("mycart", this.queue)
                // window.open(process.env.VUE_APP_MEMBER_SITE_URL + merchantCode + "/sign-in?branch_code=" + this.branchCode + "&from=mycart","_self");
            },
            triggerEventCutlery(value) {
                if (typeof value == "boolean") {
                    this.isActiveCutlery = !this.isActiveCutlery;
                }
            },
            triggerEventUseCashback(value) {
                if (typeof value == "boolean") {
                    this.isActiveUseCashback = !this.isActiveUseCashback;
                }
            },
            handleTakeAwayOrDineIn() {
                if(this.takeAwayOrDineIn == 1) {
                    this.takeAwayOrDineIn = 2;
                } else {
                    this.takeAwayOrDineIn = 1;
                }
            },
            calculateTotal() {
                if(this.itemSelect && this.itemSelect.length > 0) {
                    return this.itemSelect.reduce((total, product) => {
                        if(this.isLogined) {
                            total += product.member_price ? (product.member_price * product.quantity) : 0
                        } else {
                            total += product.price_default ? (product.price_default * product.quantity) : 0
                        }

                        return total;
                    }, 0);
                } else {
                    return 0;
                }
            },
            openModalNotes() {
                $(".btn-notes-modal").click();
            },
            selectReward() {
                if (this.isLogined) {
                    localStorage.removeItem(localStorageName('ORDER_NOTES'));
                    if (this.notes) {
                        localStorage.setItem(localStorageName('ORDER_NOTES'), this.notes);
                    }
                    this.$router.push({ name: 'MyReward', params: {
                        merchant_code: this.merchantCode,
                        type: this.pathType,
                        branch_code: this.branchCode,
                        table_code: this.tableCode,
                        from_cart: true
                    } });
                } else {
                    $('.btn-notification-sign-in-modal').click();
                }
            },
            finalTotal() {
                return this.calculateTotalAfterGST().toFixed(2);
            },
            handleOpenBoxMintyWallet() {
                this.openBoxMintyWallet = !this.openBoxMintyWallet;
            },
            checkEnableVoucher() {
                if(this.rewardSelect && this.rewardSelect.length > 0) {
                    if(this.rewardSelect[0].order_minimum == 2) {
                        if(this.calculateTotal() < this.rewardSelect[0].order_total) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
                return false;
            },
            hanldeSelectPaymentMethod() {
                if(this.memberInfo && this.memberInfo.amount_in_account) {
                    var amount_in_account = Number(this.memberInfo.amount_in_account);
                    if(Number(amount_in_account.toFixed(2)) < Number(this.finalTotal())) {
                        this.disablePayWithMintyWallet = true;
                        this.selectPaymentMethod = 2;
                        localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
                        localStorage.setItem(localStorageName('PAYMENT_METHOD'), 2);
                    } else {
                        this.disablePayWithMintyWallet = false;
                        if(this.selectPaymentMethod != 2) {
                            this.selectPaymentMethod = 1;
                            localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
                            localStorage.setItem(localStorageName('PAYMENT_METHOD'), 1);
                        }
                    }
                } else {
                    this.disablePayWithMintyWallet = true;
                    this.selectPaymentMethod = 2;
                    localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
                    localStorage.setItem(localStorageName('PAYMENT_METHOD'), 2);
                }
                
            },
            async calculateVoucherPrice() {
                this.showLoader();
                if(this.memberInfo && this.rewardSelect && this.rewardSelect.length > 0) {
                    let dataForm = {
                        branch_code: this.branch.branch_code,
                        memberId: this.memberId,
                        voucherCode:  this.rewardSelect[0].code,
                        totalOrder: this.calculateTotal()
                    };

                    await PromocodeService.calculateVoucherPrice(dataForm).then(
                        (response) => {
                            if (response.data.result.isSuccess) {
                                this.voucher_deducted_format = response.data.data.voucher_deducted_format;
                                this.totalVoucherDiscount = response.data.data.voucher_deducted;
                                this.hanldeSelectPaymentMethod();
                            } else {
                                this.snackbar = "danger";
                                this.message = response.data.result.message;
                                this.setTimeOutNotification();
                            }
                            this.hideLoader();
                        },
                        (error) => {
                            this.snackbar = "danger";
                            this.message = error;
                            this.hideLoader();
                        }
                    );
                } else {
                    this.hanldeSelectPaymentMethod();
                }
            },
            handlePay(isPayTopup = 0) {
                this.showLoader();
                let dataForm = {
                    branch_code: this.branch.branch_code,
                };

                const trimmedNotes = (this.notes && this.notes !== "" && this.notes.length > 15) ? this.notes.slice(0, 15) : this.notes;

                OrderService.checkAvailableOrdering(dataForm).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            if(response.data.data.status) {
                                dataForm = {
                                    branch_code: this.branch.branch_code,
                                    member_id: this.memberId,
                                    items: this.itemSelect,
                                    promocode: this.rewardSelect,
                                    isCutlery: this.cutleryChecked ? 1 : 0,
                                    take_away_or_dine_in: this.takeAwayOrDineIn,
                                    take_away_fee: this.totalDineInFee().toFixed(2),
                                    notes: trimmedNotes,
                                    use_cash_back: null,
                                    total_item: this.calculateTotal(),
                                    total_voucher_discount: this.totalVoucherDiscount,
                                    total_cashback_deduct: 0,
                                    total_payment_amount: this.finalTotal(),
                                    total_cashback_earned: 0,
                                    select_payment_method: this.selectPaymentMethod,
                                    gst_amount: this.convertedValueGstRate().toFixed(2),
                                    voucher_name: this.rewardSelect && this.rewardSelect.length > 0 ? this.rewardSelect[0].reward_title : "",
                                    gst_rate: this.branch.gst_rate,
                                    table_code: this.tableCode,
                                    selectSpicyValue: this.selectSpicyValue,
                                    is_pay_topup: isPayTopup
                                };

                                const numericValue = parseFloat(this.branch.gst_rate);
                                if(numericValue < 0) {
                                    dataForm.gst_amount = this.totalGSTInclusive().toFixed(2);
                                    // var gst_total_items_price = (this.calculateTotal() / (1+(Math.abs(this.branch.gst_rate) / 100))) * (Math.abs(this.branch.gst_rate) / 100);
                                    // var gst_servie_charge_fee = (this.totalDineInFee() / (1+(Math.abs(this.branch.gst_rate) / 100))) * (Math.abs(this.branch.gst_rate) / 100);
                                    // dataForm.gst_amount = (gst_total_items_price + gst_servie_charge_fee).toFixed(2);
                                    // dataForm.take_away_fee = (this.totalDineInFee() / (1+(Math.abs(this.branch.gst_rate) / 100))).toFixed(2);
                                    // dataForm.total_item = (this.calculateTotal() / (1+(Math.abs(this.branch.gst_rate) / 100))).toFixed(2);
                                }

                                const merchantCode = this.$store.state.merchantCode;
                                const branchCode = this.$store.state.branchCode;

                                if (this.isOrderPayLater) {
                                    dataForm.guest = this.guest;
                                    dataForm.is_your_friend_logined = this.isYourFriendLogined;

                                    OrderService.saveOrderQR(dataForm).then(
                                        (response) => {
                                            if (response.data.result.isSuccess) {
                                                let data = response.data.data;
                                                // this.date_time_send_to_megapos = data.date_time_send_to_megapos;
                                                // this.arrayMintyTransactionDetail = data.arrayMintyTransactionDetail ?? [];
                                                // this.arrayMintyQRTransactionDetail = data.arrayMintyQRTransactionDetail ?? [];
                                                // this.arrayMintyOnlineOrderModifierByItem = data.arrayMintyOnlineOrderModifierByItem ?? [];
                                                // this.arrayMintyQROnlineOrderModifierByItem = data.arrayMintyQROnlineOrderModifierByItem ?? [];
                                                // this.startPolling(data.transaction_id);
                                                localStorage.setItem(this.cartName, null);
                                                localStorage.removeItem(localStorageName('SELECTED_REWARD'));
                                                this.payLoad = false;
                                                this.itemSelect = [];

                                                let url =
                                                    location.protocol +
                                                    "//" +
                                                    location.host +
                                                    "/" +
                                                    this.merchantCode +
                                                    "/QR/" +
                                                    this.branchCode +
                                                    "/" +
                                                    this.tableCode +
                                                    "/payment-success/" + 
                                                    data.request_id +
                                                    "/PL";
                                                // let urlLocked =
                                                //     location.protocol +
                                                //     "//" +
                                                //     location.host +
                                                //     "/" +
                                                //     this.merchantCode +
                                                //     "/QR/" +
                                                //     this.branchCode +
                                                //     "/" +
                                                //     this.tableCode +
                                                //     "/order-locked/" + 
                                                //     data.transaction_id;
                                                // if(data.status == 2) { // is locked
                                                //     window.location.href = urlLocked;
                                                // } else {
                                                    window.location.href = url;
                                                // }
                                            } else {
                                                this.snackbar = "danger";
                                                this.message = response.data.result.message;
                                                this.setTimeOutNotification();
                                                this.payLoad = false;
                                            }
                                            this.hideLoader();
                                        },
                                        (error) => {
                                            this.snackbar = "danger";
                                            this.message = error;
                                            this.setTimeOutNotification();
                                            this.hideLoader();
                                            this.payLoad = false;
                                        }
                                    );
                                } else {
                                    OrderService.saveOrder(dataForm).then(
                                        (response) => {
                                            if (response.data.result.isSuccess) {
                                                // localStorage.removeItem(this.cartName);
                                                // localStorage.removeItem("SELECTED_REWARD");
                                                // localStorage.setItem('PAYMENT_ORDER_VOUCHER_DISCOUNT_TOTAL', this.totalVoucherDiscount ?? null)
                                                // localStorage.setItem('PAYMENT_ORDER_TOTAL', this.calculateTotal() ?? null)
                                                // localStorage.setItem('PAYMENT_ORDER_FINAL_TOTAL', this.finalTotal() ?? null)
                                                // localStorage.setItem('PAYMENT_ORDER_ID', response.data.data.transaction_id ?? '')
                                                // localStorage.setItem('PAYMENT_ORDER_INFO', (this.itemSelect ? JSON.stringify(this.itemSelect) : JSON.stringify([])))
                                                // localStorage.setItem('PAYMENT_ORDER_GST_TOTAL', this.convertedValueGstRate() ?? null)
                                                // localStorage.setItem('PAYMENT_ORDER_SERVICE_CHARGE_TOTAL', this.totalDineInFee())
                                                // localStorage.setItem('ORDER_INFORMATION_SUCCESS', (response.data.data ? JSON.stringify(response.data.data) : null))
                                                let transactionId = response.data.data.transaction_id;

                                                if (isPayTopup) {
                                                    localStorage.removeItem(this.cartName);
                                                    localStorage.removeItem(localStorageName('SELECTED_REWARD'));
                                                    this.payLoad = false;
                                                    this.itemSelect = [];
                                                    this.processPaymentTopUp(transactionId);
                                                } else {
                                                    if(this.selectPaymentMethod == 2) {
                                                        this.processPaymentStripe(transactionId);
                                                    } else {
                                                        // this.snackbar = "success";
                                                        // this.message = "Order Successfully!";
                                                        // this.setTimeOutNotification();
                                                        localStorage.removeItem(this.cartName);
                                                        localStorage.removeItem(localStorageName('SELECTED_REWARD'));
                                                        this.payLoad = false;
                                                        this.itemSelect = [];
                                                        var url = location.protocol + '//' + location.host+"/"+merchantCode;

                                                        if (this.tableInfo) {
                                                            url += "/QR/"+branchCode+"/"+this.tableCode+"/payment-success/"+transactionId;
                                                        } else {
                                                            url += "/"+branchCode+"/payment-success/"+transactionId;
                                                        }

                                                        window.location.href = url;
                                                    }
                                                }
                                            } else {
                                                this.snackbar = "danger";
                                                this.message = response.data.result.message;
                                                this.setTimeOutNotification();
                                                this.payLoad = false;
                                            }
                                            this.hideLoader();
                                        },
                                        (error) => {
                                            this.snackbar = "danger";
                                            this.message = error;
                                            this.setTimeOutNotification();
                                            this.hideLoader();
                                            this.payLoad = false;
                                        }
                                    );
                                }
                            } else {
                                this.txtNotification = 'This restaurant is currently not available for ordering. Please try again later, your order is saved in the cart.';
                                $('.btn-notification-modal').click();
                                this.hideLoader();
                            }
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                            this.hideLoader();
                            this.payLoad = false;
                        }
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.setTimeOutNotification();
                        this.hideLoader();
                        this.payLoad = false;
                    }
                );
            },
            processPaymentStripe(transaction_id) {
                this.showLoader();
                const merchantCode = this.$store.state.merchantCode;
                const branchCode = this.$store.state.branchCode;
                var urlCancel = location.protocol + '//' + location.host+"/"+merchantCode;
                var url = location.protocol + '//' + location.host+"/"+merchantCode;

                if (this.tableInfo) {
                    url += "/QR/" + branchCode + "/" + this.tableCode + "/payment-success/" + transaction_id;
                    urlCancel += "/QR/" + branchCode + "/" + this.tableCode + "/my-cart";
                } else {
                    url += "/" + branchCode + "/payment-success/" + transaction_id;
                    urlCancel += "/" + branchCode + "/my-cart";
                }

                var dataSend = {
                    success_url: url+"?session_id={CHECKOUT_SESSION_ID}&transaction_id="+transaction_id+"&price="+this.finalTotal(),
                    cancel_url: urlCancel,
                    images: "",
                    name: "Order Items",
                    unit_amount: this.finalTotal(),
                    quantity: 1,
                    branch_code: this.branchCode,
                    transaction_id: transaction_id,
                    member_id: this.memberId,
                    merchant_id: localStorage.getItem(localStorageName('MERCHANT_ID'), 0),
                    isPaymentItem: 1
                };
                OrderService.checkoutSessionStripe(dataSend).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            window.location.href = response.data.data.url
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                        this.payLoad = false;
                        this.hideLoader();
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.payLoad = false;
                    }
                );
            },
            getMemberInfo() {
                if(this.memberInfo) {
                    let dataForm = {
                        memberId: this.memberId
                    };

                    AuthService.getMemberInfo(dataForm).then(
                        (response) => {
                            if (response.result.isSuccess) {
                                localStorage.setItem(localStorageName('memberInfo'), JSON.stringify(response.data.user));
                                this.memberInfo = response.data.user;
                            } else {
                                this.snackbar = "danger";
                                this.message = response.result.message;
                                this.setTimeOutNotification();
                            }
                            this.hideLoader();
                        },
                        (error) => {
                            this.snackbar = "danger";
                            this.message = error;
                            this.hideLoader();
                        }
                    );
                }
            },
            async removeItemFromItemsSelected(id, index) {
                // const indexToRemove = this.itemSelect.findIndex(item => item.id === id);

                // if (indexToRemove !== -1) {
                    this.itemSelect.splice(index, 1);
                // }

                localStorage.removeItem(this.cartName);
                localStorage.setItem(this.cartName, JSON.stringify(this.itemSelect));
                this.showLoader();
                await this.calculateVoucherPrice();
                this.hideLoader();
                // this.hideLoader();
                // this.checkItemsSelectedStock(0);
                // this.hideLoader();
            },
            // checkMerchantAndBranch() {
            //     const merchantCode = localStorage.getItem("MERCHANT_CODE", "");
            //     const branchCode = localStorage.getItem('BRANCH_CODE', '');
            //     axios.get(process.env.VUE_APP_BASE_URL + '/check-merchant-and-branch/' + branchCode,
            //         {
            //         headers: {
            //             "MerchantCode": merchantCode
            //         }
            //         }
            //     )
            //         .then((response) => {
            //             var dataResponse = response.data;
            //             if (dataResponse.result.isSuccess) {
            //                 localStorage.setItem('MERCHANT_ID', dataResponse.data.merchant_id ?? 0)
            //                 localStorage.setItem('MERCHANT_BRAND_NAME', dataResponse.data.merchant_brand_name ?? '')
            //                 localStorage.setItem('BRANCH_INFO', dataResponse.data.branch_info ? JSON.stringify(dataResponse.data.branch_info) : '')
            //                 this.MERCHANT_BRAND_NAME = dataResponse.data.merchant_brand_name ?? '';
            //                 this.isCheckMerchantConnectedStripe = dataResponse.data.isCheckMerchantConnectedStripe;
            //             }
            //         },
            //         (error) => {
            //             var status = error.response.status
            //             console.log(status)
            //         }
            //         );
            // },
            totalDineInFee() {
                // if(this.takeAwayOrDineIn == 2 && this.branch.service_charge_rate > 0) {
                if(this.branch.service_charge_rate > 0) {
                    return ((this.calculateTotal() - this.totalVoucherDiscount) * (this.branch.service_charge_rate / 100));       
                } else {
                    return 0;
                }
            },
            convertedValueGstRate() {
                const numericValue = parseFloat(this.branch.gst_rate);

                if(numericValue > 0) { // exclusive
                    return (this.calculateTotal() - this.totalVoucherDiscount + this.totalDineInFee()) * (numericValue / 100);
                } else { // inclusive
                    return 0;
                }
            },
            calculateTotalAfterGST() {
                if(this.itemSelect && this.itemSelect.length > 0) {
                    if(parseFloat(this.branch.gst_rate) < 0) { // INCLUSIVE
                        let total = this.itemSelect.reduce((total, product) => {
                            if(this.isLogined) {
                                total += product.member_price ? (product.member_price * product.quantity) : 0
                            } else {
                                total += product.price_default ? (product.price_default * product.quantity) : 0
                            }

                            return total;
                        }, 0);

                        total += this.totalDineInFee() - this.totalVoucherDiscount;
                        return total > 0 ? total : 0;
                    } else { // EXCLUSIVE
                        let total = this.itemSelect.reduce((total, product) => {

                            if(this.isLogined) {
                            total += product.member_price ? (product.member_price * product.quantity) : 0
                            } else {
                            total += product.price_default ? (product.price_default * product.quantity) : 0
                            }

                            return total;
                        }, 0);

                        total += this.totalDineInFee() + this.convertedValueGstRate() - this.totalVoucherDiscount;
                        return total > 0 ? total : 0;
                    }
                } else {
                    return 0;
                }
            },
            calculateTotalBeforeDiscount() {
                if(this.itemSelect && this.itemSelect.length > 0) {
                    if(parseFloat(this.branch.gst_rate) < 0) { // INCLUSIVE
                        let total = this.itemSelect.reduce((total, product) => {
                            if(this.isLogined) {
                                total += product.member_price ? (product.member_price * product.quantity) : 0
                            } else {
                                total += product.price_default ? (product.price_default * product.quantity) : 0
                            }

                            return total;
                        }, 0);

                        total += this.totalDineInFee();
                        return total > 0 ? total : 0;
                    } else { // EXCLUSIVE
                        let total = this.itemSelect.reduce((total, product) => {

                            if(this.isLogined) {
                            total += product.member_price ? (product.member_price * product.quantity) : 0
                            } else {
                            total += product.price_default ? (product.price_default * product.quantity) : 0
                            }

                            return total;
                        }, 0);

                        total += this.totalDineInFee() + this.convertedValueGstRate();
                        return total > 0 ? total : 0;
                    }
                } else {
                    return 0;
                }
            },
            totalGSTInclusive() {
                var gstConverted = Math.abs(this.branch.gst_rate) / 100;
                return (this.calculateTotalAfterGST() / (1 + gstConverted)) * gstConverted;
            },
            allModifierGroups() {
                let allModifierGroups = [];

                if(this.itemSelect && this.itemSelect.length > 0) {
                    this.itemSelect.forEach(item => {
                        let modifierGroups = item.modifier_group_full || [];

                        modifierGroups.forEach(modifierGroup => {
                        allModifierGroups.push({ items: modifierGroup.items || [] });
                        });
                    });
                }

                return allModifierGroups;
            },
            getSlides() {
                let itemIds = [];
                if(this.itemSelect && this.itemSelect.length > 0) {
                    this.itemSelect.forEach(item => {
                        if (item.id) {
                            itemIds.push(item.id);
                        }
                    });
                    // Remove duplicate IDs (if any)
                    itemIds = [...new Set(itemIds)];
                }

                // this.loaderActive = true;
                var dataForm = {
                    branch_code: this.$store.state.branchCode,
                    itemArray: itemIds
                }
                SlideService.getListSlidesOnCheckout(dataForm).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            var resutltSlide = response.data.data;
                            var usedItems = [];
                            resutltSlide && resutltSlide.forEach(slide => {
                                if (slide.direct_to_suggested_adds_on === 1) {
                                    this.allModifierGroups().forEach(modifierGroup => {
                                        const selectedItem = modifierGroup.items.find(item => item.price > 0 && !usedItems.includes(item.id));
                                        if (selectedItem) {
                                            usedItems.push(selectedItem.id)
                                            slide.itemSelected = selectedItem;
                                        } else {
                                            const selectedItemPriceIsZero = modifierGroup.items.find(item => item.price == 0 && !usedItems.includes(item.id));
                                            if (selectedItemPriceIsZero) {
                                                usedItems.push(selectedItemPriceIsZero.id)
                                                slide.itemSelected = selectedItemPriceIsZero;
                                            }
                                        }
                                    });
                                }
                            });
                            this.slides = resutltSlide;
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                        // this.loaderActive = false;
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        // this.loaderActive = false;
                    }
                );

                // // Return the array of unique item IDs
                // return itemIds;
            },
            clickLink(slide) {
                if(slide && slide.destination_link != "") {
                    window.open(slide.destination_link);
                } else if(slide && slide.direct_to_menu_item != "" && !slide.direct_to_suggested_adds_on) {
                    this.$router.push({ name: 'Order', params: {
                        merchant_code: this.merchantCode,
                        type: this.pathType,
                        branch_code: this.branchCode,
                        table_code: this.tableCode,
                        itemId: slide.direct_to_menu_item ?? 0
                    } });
                }
            },
            async reloadOrder(isPay = 0, isPayTopup = 0) {
                if (this.payLoad) {
                    return;
                }

                this.payLoad = true;

                if (isPay == 1) {
                    await this.checkBranchStatus();
                }

                if (this.stopOrdering) {
                    this.txtNotification = "The restaurant is currently not available for ordering, please try again later";
                    $('.btn-notification-modal').click();
                    this.payLoad = false;
                } else {
                    this.messageErrorVoucher = "";
                    this.showLoader();
                    let dataForm = {
                        items: JSON.parse(localStorage.getItem(this.cartName, [])),
                        branch_code: this.$store.state.branchCode,
                        member_id: this.memberId,
                        promocode: this.rewardSelect,
                        total_item: this.calculateTotal(),
                    };

                    await OrderService.reload(dataForm).then(
                        async (response) => {
                            if (response.data.result.isSuccess) {
                                let dataResponse = response.data.data; 

                                if (this.tableInfo && this.tableInfo.type == 2) {  // STATIC QR
                                    if (!this.isOrderPayLater != dataResponse.stripe_connect) {
                                        window.location.reload();
                                    }
                                }

                                this.MERCHANT_BRAND_NAME = dataResponse.merchant_brand_name ?? '';
                                this.isCheckMerchantConnectedStripe = dataResponse.isCheckMerchantConnectedStripe;
                                this.itemSelect = dataResponse.items;
                                if(dataResponse.isCanPay == 0) {
                                    $('.btn-notification-out-stock-items-modal').click();
                                } else {
                                    $(".btn-close-out-stock-items").click();
                                }
                                this.isCanPay = dataResponse.isCanPay;
                                localStorage.removeItem(this.cartName);
                                localStorage.setItem(this.cartName, JSON.stringify(this.itemSelect));
                                // let priceUpdated = dataResponse.priceUpdated;

                                // if (this.isCanPay && isPay && !priceUpdated) {
                                if (this.isCanPay && this.isCanPay == 1 && isPay) {
                                    this.handlePay(isPayTopup);
                                } else {
                                    // let warningMessage = dataResponse.message;

                                    // if (warningMessage.length) {
                                    //     this.txtNotification = warningMessage;
                                    //     $('.btn-notification-modal').click();
                                    // }

                                    await this.calculateVoucherPrice();
                                    this.payLoad = false;
                                    this.hideLoader();
                                }
                            } else {
                                let warningMessage = response.data.result.message;
                                var prefix = "prefixVoucherError-";
                                if (warningMessage.startsWith(prefix)) {
                                    this.messageErrorVoucher = warningMessage.substring(prefix.length);
                                } else {
                                    this.snackbar = "danger";
                                    this.message = response.data.result.message;
                                    this.setTimeOutNotification();
                                }
                                $(".btn-close-out-stock-items").click();
                                this.payLoad = false;
                                this.hideLoader();
                            }
                        },
                        (error) => {
                            $(".btn-close-out-stock-items").click();
                            this.snackbar = "danger";
                            this.message = error;
                            this.setTimeOutNotification();
                            this.hideLoader();
                            this.payLoad = false;
                        }
                    );
                }
            },
            checkItemsSelectedStock(type) {
                this.showLoader();
                let dataForm = {
                    items: JSON.parse(localStorage.getItem(this.cartName, [])),
                    branch_code: this.$store.state.branchCode
                };

                OrderService.checkItemsSelectedStock(dataForm).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.itemSelect = response.data.data.items;
                            this.isCanPay = response.data.data.isCanPay;
                            if(response.data.data.isCanPay && response.data.data.isCanPay == 1) {
                                if(type == 1) {
                                    this.handlePay();
                                } else {
                                    this.hideLoader();
                                }
                            } else {
                                this.hideLoader();
                            }
                        } else {
                            console.log(response.data.result.message);
                            this.hideLoader();
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.hideLoader();
                    }
                );
            },
            removeItemModifierFromItemsSelected(id) {
                for (let i = 0; i < this.itemSelect.length; i++) {
                    if (this.itemSelect[i] && this.itemSelect[i].modifier_group) {
                        for (let j = 0; j < this.itemSelect[i].modifier_group.length; j++) {
                            if (this.itemSelect[i].modifier_group[j].items) {
                                for (let k = 0; k < this.itemSelect[i].modifier_group[j].items.length; k++) {
                                    if (this.itemSelect[i].modifier_group[j].items[k].id === id) {
                                        this.itemSelect[i].modifier_group[j].items.splice(k, 1);
                                        if (this.itemSelect[i].modifier_group[j].items.length === 0) {
                                            this.itemSelect[i].modifier_group.splice(j, 1);
                                        }
                                        localStorage.removeItem(this.cartName);
                                        localStorage.setItem(this.cartName, JSON.stringify(this.itemSelect));
                                        this.checkItemsSelectedStock(0);
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
            },
            selectInputStripe() {
                var input = document.getElementById('paymentMethodMintyWalletInput');
                input.checked = false;
                var input1 = document.getElementById('paymentMethodStripeInput');
                input1.checked = true;
                this.selectPaymentMethod = 2;
                localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
                localStorage.setItem(localStorageName('PAYMENT_METHOD'), 2);
            },
            selectInputMintyWallet() {
                var amount_in_account = Number(this.memberInfo.amount_in_account);
                if(Number(this.calculateTotalAfterGST()) > 0 && Number(amount_in_account) >= Number(this.calculateTotalAfterGST())) {
                    var input = document.getElementById('paymentMethodStripeInput');
                    input.checked = false;
                    var input1 = document.getElementById('paymentMethodMintyWalletInput');
                    input1.checked = true;
                    this.selectPaymentMethod = 1;
                    localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
                    localStorage.setItem(localStorageName('PAYMENT_METHOD'), 1);
                }
                
            },
            calculateTotalYouSave() {
                var data = this.itemSelect ?? [];
                let total = 0;
                data && data.length > 0 && data.forEach(item => {
                    if (this.isLogined && item.member_price_no_modifier_option > 0) {
                        total += item.price_default_no_modifier_option - item.member_price_no_modifier_option;
                    }
                });
                return total;
            },
            getMainItemPriceAndModifiersPrice(item) {
                var priceMainAndModifer = 0;
                priceMainAndModifer = this.isLogined && item.member_price_no_modifier_option ? item.member_price_no_modifier_option * item.quantity : item.price_default_no_modifier_option * item.quantity;
                var totalPriceModifer = 0;

                if (item.choice) {
                    item.choice_selected.items.forEach((itemChoice) => {
                        itemChoice.item_detail.modifier_group.forEach((group) => {
                            group.items.forEach((itemModifier) => {
                                totalPriceModifer += itemModifier.price_default * itemModifier.quantity * itemChoice.qty;

                                if (itemModifier.item_in_mog && itemModifier.item_in_mog.modifier_group) {
                                    itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                                        subGroup.items.forEach((subItemModifier) => {
                                            totalPriceModifer += subItemModifier.price_default * subItemModifier.quantity * itemModifier.quantity * itemChoice.qty;
                                        });
                                    })
                                }
                            });
                        });
                    })
                } else {
                    item.modifier_group.forEach((group) => {
                        group.items.forEach((itemModifier) => {
                            totalPriceModifer += itemModifier.price_default * itemModifier.quantity;

                            if (itemModifier.item_in_mog && itemModifier.item_in_mog.modifier_group) {
                                itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                                    subGroup.items.forEach((subItemModifier) => {
                                        totalPriceModifer += subItemModifier.price_default * subItemModifier.quantity * itemModifier.quantity;
                                    });
                                })
                            }
                        });
                    });
                }

                return this.formatPrice(priceMainAndModifer + (totalPriceModifer * item.quantity));
            },
            getMainItem(item) {
                var priceMainAndModifer = 0;
                priceMainAndModifer = this.isLogined && item.member_price_no_modifier_option ? item.price_default_no_modifier_option * item.quantity : 0;
                var totalPriceModifer = 0;

                if (item.choice) {
                    item.choice_selected.items.forEach((itemChoice) => {
                        itemChoice.item_detail.modifier_group.forEach((group) => {
                            group.items.forEach((itemModifier) => {
                                totalPriceModifer += itemModifier.price_default * itemModifier.quantity * itemChoice.qty;

                                if (itemModifier.item_in_mog && itemModifier.item_in_mog.modifier_group) {
                                    itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                                        subGroup.items.forEach((subItemModifier) => {
                                            totalPriceModifer += subItemModifier.price_default * subItemModifier.quantity * itemModifier.quantity * itemChoice.qty;
                                        });
                                    })
                                }
                            });
                        });
                    })
                } else {
                    item.modifier_group.forEach((group) => {
                        group.items.forEach((itemModifier) => {
                            totalPriceModifer += itemModifier.price_default * itemModifier.quantity;

                            if (itemModifier.item_in_mog && itemModifier.item_in_mog.modifier_group) {
                                itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                                    subGroup.items.forEach((subItemModifier) => {
                                        totalPriceModifer += subItemModifier.price_default * subItemModifier.quantity * itemModifier.quantity;
                                    });
                                })
                            }
                        });
                    });
                }
                
                return priceMainAndModifer > 0 ? this.formatPrice(priceMainAndModifer + (totalPriceModifer * item.quantity)) : "";
            },
            removeReward() {
                localStorage.removeItem(localStorageName('SELECTED_REWARD'));
                location.reload();
            },
            showQuantityForMainItem(itemIndex) {
                this.clearIsEditQuantity();
                if (itemIndex !== -1) {
                    if(!this.itemSelect[itemIndex].isEditQuantity) {
                        this.itemSelect[itemIndex].isEditQuantity = true;
                    } else {
                        this.itemSelect[itemIndex].isEditQuantity = false;
                    }
                }
            },
            async handleQuantityForMainItem(mainItem, type, itemIndex) { // 1 is - | 2 is +
                if (itemIndex !== -1 && type == 1) {
                    if (this.itemSelect[itemIndex].quantity === 1) {
                        // If quantity is going to be decremented to 0, show confirm dialog
                        if (confirm("Are you sure you want to remove this item '" + mainItem.item_name + "' from your cart?")) {
                            this.decrementItemQuantity(itemIndex);
                        }
                    } else {
                        this.decrementItemQuantity(itemIndex);
                    }
                } else if (itemIndex !== -1 && type == 2) {
                    this.itemSelect[itemIndex].quantity = this.itemSelect[itemIndex].quantity + 1;
                    localStorage.removeItem(this.cartName);
                    localStorage.setItem(this.cartName, JSON.stringify(this.itemSelect));
                }
                this.showLoader();
                await this.calculateVoucherPrice();
                this.hideLoader();
            },

            decrementItemQuantity(itemIndex) {
                this.itemSelect[itemIndex].quantity = this.itemSelect[itemIndex].quantity - 1;
                if (this.itemSelect[itemIndex].quantity === 0) {
                    this.itemSelect.splice(itemIndex, 1);
                }
                localStorage.removeItem(this.cartName);
                localStorage.setItem(this.cartName, JSON.stringify(this.itemSelect));
            },

            clearIsEditQuantity() {
                this.itemSelect.forEach(item => {
                    delete item.isEditQuantity;
                });
                var dataItems = JSON.parse(localStorage.getItem(this.cartName, []));
                localStorage.removeItem(this.cartName);
                dataItems.forEach(itemObject => {
                    delete itemObject.isEditQuantity;
                });
                localStorage.setItem(this.cartName, JSON.stringify(dataItems));
            },
            hideDiv(event) {
                const clickedElement = event.target;

                // Check if the clicked element doesn't have the class 'boxEditQuantityNormal'
                if (clickedElement && !clickedElement.classList.contains('boxEditQuantity')) {
                    this.clearIsEditQuantity();
                }
            },
            countQuantityItems() {
                var totalQuantityItems = 0;

                this.itemSelect.forEach((mainItem) => {
                    totalQuantityItems += mainItem.quantity;
                    // mainItem.modifier_group.forEach((group) => {
                    //     group.items.forEach((itemModifier) => {
                    //         totalQuantityItems += itemModifier.quantity;
                    //     });
                    // });
                });

                return totalQuantityItems;
            },
            gotoPath(name) {
                this.$router.push({ name: name, params: {
                    merchant_code: this.merchantCode,
                    type: this.pathType,
                    branch_code: this.branchCode,
                    table_code: this.tableCode
                } });
            },
            getTopupList() {
                if(this.memberInfo) {
                    MemberService.getTopupList().then(
                        (response) => {
                            this.promotionalTopupList = response.data.data.promotionTopup;
                            this.topupList = response.data.data.topup;
                        },
                        (error) => {
                            console.log("error", error);
                            // this.snackbar = "danger";
                            // this.message = error;
                            // this.setTimeOutNotification();
                            this.hideLoader();
                        }
                    );
                }
            },
            processPaymentTopUp(transactionId = null) {
                var item = this.topupCurrentlyHandle;
                const merchantCode = this.$store.state.merchantCode;
                var urlCancel = location.protocol + '//' + location.host+"/"+merchantCode;
                var url = location.protocol + '//' + location.host+"/"+merchantCode;

                if (this.tableInfo) {
                    url += "/QR/" + this.branchCode + "/" + this.tableCode + "/my-cart";
                    urlCancel += "/QR/" + this.branchCode + "/" + this.tableCode + "/my-cart";
                } else {
                    url += "/" + this.branchCode + "/my-cart";
                    urlCancel += "/" + this.branchCode + "/my-cart";
                }

                var payment_amount = new Intl.NumberFormat("en", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                                }).format(item.payment_amount)
                var dataSend = {
                    success_url: url+"?session_id={CHECKOUT_SESSION_ID}&price="+item.payment_amount+"&id="+item.id+"&transaction_id="+transactionId,
                    cancel_url: urlCancel,
                    images: "",
                    name: "TOP UP "+payment_amount,
                    unit_amount: item.payment_amount,
                    quantity: 1,
                    branch_code: this.branchCode,
                    transaction_id: transactionId,
                    isTopupOnMember: 1,
                    topup_id: item.id,
                    member_id: this.memberInfo.id
                };
                OrderService.checkoutSessionStripe(dataSend).then(
                    (response) => {
                    if (response.data.result.isSuccess) {
                        window.location.href = response.data.data.url
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.setTimeOutNotification();
                        this.hideLoader();
                    }
                );
            },
            openModalConfirmTopup(item) {
                this.topupCurrentlyHandle = item;
                $(".btn-confirm-topup-modal").click();
            },
            openModalConfirmPromotionalTopup(item) {
                let topupAmount = item.payment_amount * this.finalTotal();
                this.topupCurrentlyHandle = {
                    id: item.id,
                    topup_amount: topupAmount,
                    payment_amount: topupAmount,
                    expiry_number: item.expiry_number,
                    expiry_unit: item.expiry_unit,
                    limit_number: item.limit_number,
                    limit_type: item.limit_type,
                    limit_unit: item.limit_unit,
                    promotion_type: item.promotion_type
                };
                $(".btn-confirm-topup-modal").click();
            },
            checkMemberPermissions() {
                if(this.memberInfo) {
                    MemberService.checkMemberPermissions().then(
                        (response) => {
                        if (response.data.result.isSuccess) {
                            this.memberPermissions = response.data.data;
                        }
                        },
                        (error) => {
                            console.log(error)
                        }
                    );
                }
            },
            getOrderByOrderId(order_id) {
                var dataForm = {
                    member_id: this.memberId,
                    transaction_id: order_id,
                    branch_code: this.branchCode,
                };
        
                OrderService.getOrderByOrderId(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        if(response.data.data.date_time_send_to_megapos !== this.date_time_send_to_megapos) {
                            clearInterval(this.intervalId);
                            clearTimeout(this.timeoutId);
                            localStorage.setItem(this.cartName, null);
                            localStorage.removeItem(localStorageName('SELECTED_REWARD'));
                            this.payLoad = false;
                            this.itemSelect = [];

                            let url =
                                location.protocol +
                                "//" +
                                location.host +
                                "/" +
                                this.merchantCode +
                                "/QR/" +
                                this.branchCode +
                                "/" +
                                this.tableCode +
                                "/payment-success/" + 
                                response.data.data.transaction_id;
                            let urlLocked =
                                location.protocol +
                                "//" +
                                location.host +
                                "/" +
                                this.merchantCode +
                                "/QR/" +
                                this.branchCode +
                                "/" +
                                this.tableCode +
                                "/order-locked/" + 
                                response.data.data.transaction_id;
                            if(response.data.data.status == 2) { // is locked
                                window.location.href = urlLocked;
                            } else {
                                window.location.href = url;
                            }
                        }
                    }
                },
                (error) => {
                    console.log("error", error);
                }
                );
            },
            startPolling(order_id) {
                this.getOrderByOrderId(order_id);

                this.intervalId = setInterval(() => {
                    this.getOrderByOrderId(order_id);
                }, 3000);

                this.timeoutId = setTimeout(() => {
                    clearInterval(this.intervalId);
                    clearTimeout(this.timeoutId);
                    this.hideLoader();
                    localStorage.setItem(this.cartName, null);
                    localStorage.removeItem(localStorageName('SELECTED_REWARD'));
                    this.payLoad = false;
                    this.itemSelect = [];
                    $(".btn-timeout-order-modal").click();
                    // this.deleteItemsInOrders();
                    
                }, 60000);
            },
            closedModalOrderFail() {
                location.reload();
            },
            // deleteItemsInOrders() {
            //     console.log(this.itemSelect);
            //     var dataForm = {
            //         arrayMintyTransactionDetail: this.arrayMintyTransactionDetail,
            //         arrayMintyQRTransactionDetail: this.arrayMintyQRTransactionDetail,
            //         arrayMintyOnlineOrderModifierByItem: this.arrayMintyOnlineOrderModifierByItem,
            //         arrayMintyQROnlineOrderModifierByItem: this.arrayMintyQROnlineOrderModifierByItem,
            //     };
        
            //     OrderService.deleteItemInOrderFailed(dataForm).then(
            //     (response) => {
            //         if (response.data.result.isSuccess) {
            //             console.log("response", response);
            //             this.hideLoader();
            //             $(".btn-timeout-order-modal").click();
            //         } else {
            //             this.snackbar = "danger";
            //             this.message = response.data.result.message;
            //             this.setTimeOutNotification();
            //         }
            //     },
            //     (error) => {
            //         this.snackbar = "danger";
            //         this.message = error;
            //         this.setTimeOutNotification();
            //         this.hideLoader();
            //     }
            //     );
            // },
            async checkBranchStatus() {
                var dataForm = {
                    branch_code: this.branchCode
                }

                await OrderService.checkbranchStatus(dataForm).then(
                    (response) => {
                        this.stopOrdering = response.data.data.stop_ordering;
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.setTimeOutNotification();
                        this.hideLoader();
                    }
                );
            },
            selectNonSpicy() {
                var input = document.getElementById('spicy');
                input.checked = false;
                var input1 = document.getElementById('non-spicy');
                input1.checked = true;
                this.selectSpicyValue = "不辣 Non-Spicy";
            },
            selectSpicy() {
                var input = document.getElementById('spicy');
                input.checked = true;
                var input1 = document.getElementById('non-spicy');
                input1.checked = false;
                this.selectSpicyValue = "辣 Spicy";
            },
            getModifierItemsInModifierItem(item) {
                console.log('item', item);
                var name = [];
                item.modifier_group.forEach((group) => {
                    group.items.forEach((itemModifier) => {
                        name.push(itemModifier.item_name);
                    });
                });
                return name && name.length > 0 ? name.join(', ') : "";
            }
        }
    };
</script>
<style>
    input::placeholder {
        font-size: 15px !important;
        text-align: right !important;
        font-weight: 100 !important;
        /* padding-left: 10px; */
    }
    input {
        text-align: right !important;
    }
    .visa {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .visaActive {
        width:50%;text-align:left;border: 5px solid var(--color);border-radius: 5px;height: 80px;
    }
    .master {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .masterActive {
        width:50%;text-align:left;border: 5px solid var(--color);border-radius: 5px;height: 80px;
    }
    .googlePay {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .googlePayActive {
        width:50%;text-align:left;border: 5px solid var(--color);border-radius: 5px;height: 80px;
    }
    .applePay {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .applePayActive {
        width:50%;text-align:left;border: 5px solid var(--color);border-radius: 5px;height: 80px;
    }
    .text-bold-500 {
        color: black;font-weight: 500;
    }
    .text-bold-400 {
        color: black;font-weight: 400;
    }
    .line {
        width: 100%; height: 5px;background:#bdbdbd;
    }
    .text-end {
        color:black;width:20%;text-align: end;
    }
    .line {
        height: 5px;
        background-color: 'gray'
    }
    .flex-container {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.back-button {
    width: 10%;
    height: 100%;
}

.cart-title {
    width: 90%;
    align-items: center;
    /* justify-content: center; */
    /* display: flex; */
    color: black;
    font-size: 15px;
    font-weight: bold;
}
.flex-container {
    justify-content: space-between;
    display: inline-flex;
    gap: 5px;
    align-items: center;
    width: 100%;
}

.location-icon {
    width: 10%;
}

.branch-info {
    /* width: 30%; */
    color: black;
    line-break: anywhere;
}

.takeaway-dinein-button {
    width: 30%;
    /* border: 1px solid #96B7D8red; */
    align-items: center;
    justify-content: center;
    display: flex;
    /* border-radius: 3px; */
    height: fit-content;
    /* border-radius: 10px; */
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.order-items-header {
    font-size: 18px;
    color: black;
    font-weight: 800;
}

.selected-items {
    width: 100%;
    margin-top: 10px;
    display: inline-flex;
}

.item-image {
    width: 30%;
}

.item-thumbnail {
    width: 50px;
    height: 50px;
}

.item-details {
    width: 70%;
}

.item-name,
.modifier-name {
    width: 65%;
    color: black;
}

.item-quantity,
.modifier-quantity {
    width: 5%;
    color: black;
}

.item-price,
.modifier-price {
    width: 30%;
    text-align: right;
    color: black;
}

.modifier-options,
.modifier-option {
    width: 100%;
    display: inline-flex;
}
.total-section,
.fee-section {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

.total-label,
.fee-label {
    color: black;
    width: 65%;
}

.total-amount,
.fee-amount {
    color: black;
    width: 35%;
}

.divider {
    width: 100%;
    border-top: 1px solid black;
    margin: 10px 0;
}
.flex-container {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}



.item-icon {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 30px;
}

.icon-image {
    width: 30px;
    height: 30px;
}

.item-label {
    color: black;
}

.toggle-container {
    width: 100%;
}

.toggle-wrapper {
    display: flex;
    align-items: center;
}

.toggle-control {
    width: 100%;
}

@media (min-width: 576px) {
    .toggle-control {
        width: auto;
    }
}
.card-body {
    /* Add any common styles for the card body here */
}

.flex-container {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

.inline-flex-container {
    display: inline-flex;
    align-items: flex-start;
    gap: 5px;
}

.icon-container {
    align-items: center;
    justify-content: center;
    display: flex;
}

.title-text {
    /* font-size: 20px; */
    color: black;
}

.text-container {
    color: black;
}

.orange-text {
    color: var(--color);
}

.bold-text {
    font-weight: 600;
}

#eventIcon {
    width: 30px;
}

.cashback-icon {
    width: 25px;
    height: 25px;
}
.wallet-icon {
    width: 30px;
    height: 30px;
}

.radio-input {
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}

.horizontal-line {
    margin: 0;
}

.margin-top-text {
    margin-top: 5px;
}

.not-enough-text {
    width: 100%;
    margin-top: 5px;
}

.inline-flex-container.align-items-center {
    align-items: center;
}

.white-bold-text {
    color: white;
    font-size: 18px;
    font-weight: 800;
}

.voucher-container {
    width: 100%;
    height: 100%;
    background: #EB472F;
    border-radius: 3px;
    position: relative;
}

.padding-container {
    padding: 10px;
}

.voucher-text {
    color: white;
    font-size: 12px;
    font-weight: 600;
}

.orange-bold-text {
    color: var(--color);
    font-size: 20px;
    font-weight: 600;
}

.grid-container {
    display: grid;
}

.bold-text {
    color: black;
    font-weight: 500;
    font-size: 20px;
}

.orange-bold-text {
    color: var(--color);
    font-weight: 500;
    font-size: 20px;
}

.light-text {
    color: black;
    font-weight: 200;
}

.orange-light-text {
    color: var(--color);
    font-weight: 200;
}

.disabled-pay-button {
    /* opacity: 0.5; */
    padding: 10px 10px;
    background: white;
    color: gray;
    font-size: 18px;
    font-weight: 500;
    border: 0.5px solid gray;
    text-align: center;
    border-radius: 10px;
}

.pay-button {
    padding: 10px 10px;
    background: var(--color);
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    border-radius: 10px;
}

.div_root_cutlery {
    display: inline-flex; justify-content: space-between;width: 100%; height: 40px;
}

.cutlery-icon {
    width: 30px;
    height: 30px;
}

.icon-container {
    align-items: center;
    justify-content: center;
    display: flex;
}

.text-container {
    color: black;
}

.inline-flex-container {
    display: inline-flex;
    align-items: flex-start;
    gap: 5px;
}
.text-order-item {
    color:black;
}
.text-introduce {
    margin-top: 8px;
    font-size: 14px;color:black;font-style: italic;
}
.text-introduce span {
    font-size: 14px;color:#1a73e8 !important;text-decoration: underline;cursor: pointer;
}
.div-order-items {
    width: 100%;display: inline-flex;
    margin-top: 8px;
}
.div-items {
    width: 100%;display: inline-flex;
}
.item-name {
    width: 65%;color:black;
}
.item-quantity {
    width: 5%;color:black
}
.item-price-format {
    width:30%;text-align: right;color:black;
}
.div-item-total {
    justify-content: end; width: 100%; gap: 20px; align-items: center; margin-bottom: 10px;
}
.text-item-total {
    justify-content: space-between; gap: 10px;
}
.div_notes {
    display:inline-flex;align-items: center;gap: 5px;
}
.image_notes {
    align-items: center;justify-content: center;display: flex;width: 30px;
}
.color-black {
    color:black;
}
.div_arrow {
    display:inline-flex;align-items: center;gap: 5px;justify-content: center;
}
.custom-note {
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}

.icon-next {
  width: 20px;
  color: gray !important;
}

.color-green {
  color: green !important; /* You can adjust the color value as needed */
}

.md-30 {
  font-size: 30px; /* Or adjust the font size as needed */
}

.order-value-is-not {
    width: 100%;
    color: #F04B5E;
    font-size: 13.5px;
}
.reward_title {
    color: gray;border: 1px solid gray;padding-left: 10px;padding-right: 10px;border-radius: 2px;
}
.select-voucher {
    padding-left: 10px;padding-right: 10px;border-radius: 2px;
}
.icon-select-voucher {
    width: 20px;color:gray !important;
}
.reward_title_acitve {
    color: var(--color);border: 1px solid var(--color);padding-left: 10px;padding-right: 10px;border-radius: 2px;
}
.banner {
  position: relative;
}
.container_modifier {
    /* display: contents; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;

}
.child_modifier {
    flex: 1;
    display: contents;
}
input[type='radio'] {
    accent-color: green;
}
.modal-dialog {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.modal-content {
  margin: 0 auto;
}
</style>
  